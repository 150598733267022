import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ClaimHeader from "../../components/claimComponents/ClaimHeader";
import styles from "../../css/Claim/claimContractDetail.module.css";
import { useSelector } from "react-redux";
import claimMainContract_check from "../../assets/claimMainContract_check.svg";

function ClaimContractDetail() {
  const numberWithCommas = (x) => {
    if (!x) return "";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const modifyCertUrltoEn = (url) => {
    return url.replace('otptDiv=A', 'otptDiv=B');
  };
  const location = useLocation();
  // location.state에서 필요한 state 값을 추출
  const { selectedInsurance } = location.state;
  const cardLink = selectedInsurance?.Contract?.certificate_view_url;
  const cardLinkEn = modifyCertUrltoEn(cardLink);

  const token = useSelector((state) => state.cookie.cookie);
  const userName = token?.name || "";
  const btnFilterArray = ["기본정보", "보장내용", "가입 증명서"];
  const [isActive, setIsActive] = useState(0);

  const basicInfo = {
    contractNumber: selectedInsurance ? process.env.REACT_APP_POLICY_NO : "",
    insurancePeriod: selectedInsurance
      ? `${selectedInsurance.Contract.insurance_start_date} ~ ${selectedInsurance.Contract.insurance_end_date}`
      : "",
    contractor: selectedInsurance ? selectedInsurance.contractor : "",
    price: selectedInsurance
      ? `${numberWithCommas(selectedInsurance.insurance_premium)}원`
      : "",
  };

  // 보장내용에서 표시할 데이터
  const coverageInfo = {
    claimProcedure: selectedInsurance ? "보험금 청구 절차 내용" : "",
    insuranceTarget: selectedInsurance ? "보험 가입 대상 내용" : "",
    // 추가 필드에 대한 처리
  };

  return (
    <>
      <ClaimHeader titleText="해외여행보험" />
      <div className={styles.section}>
        <div className={styles.sectionWrap}>
          <h3>해외여행 실손의료비보험</h3>
          <div className={styles.insuPriceContents}>
            <div className={styles.insuPriceContentsWrap}>
              <div className={styles.insuPriceFlexCol}>
                <div className={styles.contentsFlexRow}>
                  <p>보험료(일시납)</p>
                  <span>정상</span>
                </div>
                <span className={styles.span}>{basicInfo.price}</span>
              </div>
            </div>
          </div>
          <div className={styles.section_2nd}>
            <div className={styles.insuUserInfo}>
              <div className={styles.filterBtnContents}>
                <ul>
                  {btnFilterArray.map((e, i) => {
                    return (
                      <li
                        key={i}
                        className={`${isActive === i ? styles.active : styles.non_active
                          }`}
                        onClick={() => {
                          setIsActive(i);
                        }}
                      >
                        {e}
                      </li>
                    );
                  })}
                </ul>
              </div>
              {isActive === 0 && (
                <div className={styles.userInfoCard}>
                  <p>기본정보</p>
                  <div className={styles.userBasicInfoBox}>
                    <div className={styles.userBasicInfoBoxWrap}>
                      <ul>
                        <li>
                          <span>증권번호</span>
                          <p>{basicInfo.contractNumber}</p>
                        </li>
                        <li>
                          <span>보험기간</span>
                          <p>{basicInfo.insurancePeriod}</p>
                        </li>
                        <li>
                          <span>계약자</span>
                          <p>{selectedInsurance.Contract.User?.contractor_name}</p>
                        </li>
                        <li>
                          <span>피보험자</span>
                          <p>{userName}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.userPayInfo}>
                    <p>납입정보</p>
                    <div className={styles.userBasicInfoBox}>
                      <div className={styles.userBasicInfoBoxWrap}>
                        <ul>
                          <li>
                            <span>납입방법</span>
                            <p></p>
                          </li>
                          <li>
                            <span>납입주기</span>
                            <p></p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isActive === 1 && (
                <div className="">
                  {/* 보장내용에 대한 내용을 여기에 표시하세요 */}
                  <div className={styles.userInfoCard}>
                    <p>보장내용</p>
                    <div className={styles.userBasicInfoBox}>
                      <div className={styles.userBasicInfoBoxWrap}>
                        <ul>
                          <li>
                            <span>보험금 청구 절차</span>
                            <p>{coverageInfo.claimProcedure}</p>
                          </li>
                          <li>
                            <span>보험 가입 대상</span>
                            <p>{coverageInfo.insuranceTarget}</p>
                          </li>
                          {/* 추가 필드에 대한 처리 */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isActive === 2 && (
                <div className={styles.cardsList}>
                  {/* 보장내용에 대한 내용을 여기에 표시하세요 */}
                  <div className={styles.userInfoCards}>
                    <a
                      href={`${cardLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={styles.cardLinks}>
                        <img src={claimMainContract_check} alt="doc" />
                        <p>
                          국문 증명서
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className={styles.userInfoCards}>
                    <a
                      href={`${cardLinkEn}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={styles.cardLinks}>
                        <img src={claimMainContract_check} alt="doc" />
                        <p>
                          영문 증명서
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimContractDetail;
