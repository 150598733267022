import React from "react";
import styles from "../../css/Claim/claimIntro.module.css";
import ClipLogin from "../../components/claimComponents/ClipLogin";
import GoNice from "../../components/niceComponents/GoNice";
import LeftArrow from "../../assets/commonLeftArrow.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function ClaimIntro() {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentSearch = new URLSearchParams(location.search);
  const returnPath = currentSearch.get("redirect") || "/";

  if (isAuthenticated) {
    window.open(returnPath, "_self");
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.contents}>
          <div className={styles.headers}>
            <img src={LeftArrow} alt="LeftAllow" />
          </div>
          <div className={styles.contentsTitle}>
            <div className={styles.logo}>
              <img src="/images/insuRETrust.png" alt="insuRETrust" />
            </div>
            <p>
              <span>인증수단</span>을 선택해주세요.
            </p>
          </div>
          <GoNice />
          <div className={styles.boundaryContents}>
            <div className={styles.line}></div>
            <p>또는</p>
            <div className={styles.line}></div>
          </div>
          <ClipLogin />
        </div>
      </div>
    </>
  );
}

export default ClaimIntro;
