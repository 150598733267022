// utils/birthDate.js
export const formatDateString = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  return { year, month, day };
};

export const formatKoreanDate = (dateString) => {
  const { year, month, day } = formatDateString(dateString);
  return `${year}년 ${parseInt(month, 10)}월 ${parseInt(day, 10)}일`;
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatPersonalId = (dateOfBirth, gender) => {
  const shortBirthDate = dateOfBirth.slice(2, 8);
  const year = parseInt(dateOfBirth.substring(0, 4));
  const baseCode = year >= 2000 ? 2 : 0;
  const genderCode = (baseCode + parseInt(gender)).toString();
  return `(${shortBirthDate} - ${genderCode}******)`;
};

export const formatBirthDateInput = (value) => {
  return value
    .replace(/[^0-9]/g, "") // 숫자가 아닌 문자 제거
    .replace(/^(\d{4})(\d{2})(\d{2})$/, "$1$2$3"); // YYYYMMDD 형식으로 변환
};
