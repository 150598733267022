import React from "react";
import { useSelector } from "react-redux";
import styles from "../../css/Comnons/Button.module.css";
import doubleChk from "../../assets/doubleChk.svg";
import { PLAN_TYPES } from "../../api/ConfirmPlanData";

const LiteButton = ({
  showLiteButton,
  handleShowLite,
  isIndemnityPath,
  hasDeparted,
}) => {
  const selectedData = useSelector((state) => state.plan.selectedData);

  if (!isIndemnityPath || hasDeparted || !showLiteButton) {
    return null;
  }

  return (
    <div
      className={`${styles.liteButton} ${
        selectedData?.name === PLAN_TYPES.LITE ? styles.selected : ""
      }`}
      onClick={handleShowLite}
    >
      <img src={doubleChk} alt="doubleChk" />
      <p>최소한의 담보/보장 초실속 플랜을 원하신다면?</p>
    </div>
  );
};

export default LiteButton;
