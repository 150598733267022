import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "../../css/nice/nice.module.css";
import { login } from "../../redux/store";
import phoneLogin from "../../assets/phoneLogin.svg";

const GoNice = () => {
  const [niceWin, setNiceWin] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    window.receiveToken = (result) => {
      dispatch(login(JSON.stringify(result)));
      const myHeaders = new Headers();
      myHeaders.append("token", result.token);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      // getRedirectPath 함수를 useEffect 내부로 이동
      const getRedirectPath = () => {
        const params = new URLSearchParams(location.search);
        const redirectPath = params.get("redirect");
        return redirectPath || "/";
      };

      fetch("/trip-api/auth/getCookie", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (niceWin) {
            niceWin.close();
          }
          const redirectPath = getRedirectPath();
          navigate(redirectPath);
        })
        .catch((error) => {
          console.error(error);
          setError("인증 처리 중 오류가 발생했습니다. 다시 시도해 주세요.");
        });
    };
  }, [navigate, niceWin, dispatch, location]);

  const handleClick = () => {
    const newWindow = window.open(
      `${process.env.REACT_APP_BASE_URL}/checkplus_main`,
      "popupChk",
      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
    );
    setNiceWin(newWindow);
  };

  return (
    <>
      <div className={styles.phoneLoginBtn} onClick={handleClick}>
        <img src={phoneLogin} alt="" />
        <p>휴대폰 본인인증 로그인</p>
      </div>
      {error && (
        <p className={styles.errorMessage} role="alert">
          {error}
        </p>
      )}
    </>
  );
};

export default GoNice;
