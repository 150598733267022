import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/calculationProcessCSS/Confirm.module.css";
import Button from "../../components/common/Button";
import { downloadPDF } from "../../utils/pdfUtils";
import {
  setTotalPrice,
  setSelectedPlanName,
  selectData,
} from "../../redux/store";
import { getGenderString } from "../../components/calculationProcessPart/Gender";
import ModifyModal from "../../components/modal/Modifymodal";
import { formatKoreanDate } from "../../utils/birthDate";
import { formatPrice, formatKoreanPrice } from "../../utils/formatPrice";
import { PLAN_TYPES, PLAN_KEYS, PLAN_LIST } from "../../api/ConfirmPlanData";
import downLoad from "../../assets/downLoad.svg";
import ConfirmPDF from "../../components/calculationProcessPart/ConfirmPDF";

function Confirm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [userAge, setUserAge] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  // Redux selectors
  const hasDeparted = useSelector((state) => state.hasDeparted.isDeparted);
  const selectedStartDate = useSelector(
    (state) => state.calendar.selectedStartDate
  );
  const selectedEndDate = useSelector(
    (state) => state.calendar.selectedEndDate
  );
  const userGender = useSelector((state) => state.user.gender);
  const userDateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const priceData = useSelector((state) => state.priceData.priceData);
  const totalPrice = useSelector((state) => state.totalPrice.totalPrice);
  const selectedData = useSelector((state) => state.plan.selectedData);
  const companions = useSelector((state) => state.companions);
  const pdfBlobs = useSelector((state) => state.pdf.pdfBlobs);
  const isFromCsvUpload = useSelector((state) => state.pdf.isFromCsvUpload);

  const [activePlan, setActivePlan] = useState(() => {
    if (hasDeparted) return "DEPARTED";
    if (selectedData) {
      const planType = PLAN_KEYS[selectedData.name];
      if (planType && priceData?.[planType]) {
        return planType;
      }
    }
    return "BASIC";
  });

  const currentPlanForPDF = useMemo(() => {
    if (hasDeparted) return "DEPARTED";
    return activePlan;
  }, [hasDeparted, activePlan]);

  const handleDownloadClick = () => {
    if (pdfBlobs[currentPlanForPDF]) {
      downloadPDF(pdfBlobs[currentPlanForPDF], currentPlanForPDF);
    }
  };

  const csvData = useMemo(() => {
    try {
      const dataParam = searchParams.get("data");
      return dataParam ? JSON.parse(decodeURIComponent(dataParam)) : null;
    } catch (error) {
      console.error("CSV 데이터 파싱 오류:", error);
      return null;
    }
  }, [searchParams]);

  const userInfo = useMemo(() => {
    if (csvData) {
      const contractor = csvData.inspeInfos[0];
      return {
        gender: contractor.gndrCd,
        dateOfBirth: contractor.inspeBdt,
        startDate: csvData.insBgnDt,
        endDate: csvData.insEdDt,
        totalCount: parseInt(csvData.inspeCnt),
        companions: csvData.inspeInfos.slice(1),
      };
    }

    const departedData = priceData?.DEPARTED;
    if (hasDeparted && departedData) {
      const inspeInfos = departedData.opapiGnrCoprCtrInspeInfCbcVo;
      return {
        gender: inspeInfos[0].gndrCd,
        dateOfBirth: userDateOfBirth,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        totalCount: inspeInfos.length,
        companions: inspeInfos.slice(1),
      };
    }

    return {
      gender: userGender,
      dateOfBirth: userDateOfBirth,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      totalCount: companions?.length + 1,
      companions: companions || [],
    };
  }, [
    csvData,
    hasDeparted,
    priceData,
    userGender,
    userDateOfBirth,
    selectedStartDate,
    selectedEndDate,
    companions,
  ]);
  useEffect(() => {
    if (selectedData && !hasDeparted) {
      const planType = PLAN_KEYS[selectedData.name];
      if (planType && priceData?.[planType]) {
        setActivePlan(planType);
      }
    }
  }, [selectedData, priceData, hasDeparted]);

  useEffect(() => {
    const checkButtonActive = () => {
      const hasRequiredData =
        userInfo.startDate &&
        userInfo.endDate &&
        userInfo.dateOfBirth &&
        (hasDeparted
          ? priceData?.DEPARTED?.ttPrem
          : priceData?.[activePlan]?.ttPrem);

      const isValidDates = () => {
        if (!userInfo.startDate || !userInfo.endDate) return false;
        const start = new Date(
          userInfo.startDate.substring(0, 4),
          parseInt(userInfo.startDate.substring(4, 6)) - 1,
          userInfo.startDate.substring(6, 8)
        );
        const end = new Date(
          userInfo.endDate.substring(0, 4),
          parseInt(userInfo.endDate.substring(4, 6)) - 1,
          userInfo.endDate.substring(6, 8)
        );
        return start <= end;
      };

      setIsButtonActive(hasRequiredData && isValidDates());
    };

    checkButtonActive();
  }, [userInfo, priceData, activePlan, hasDeparted]);

  useEffect(() => {
    if (hasDeparted) {
      dispatch(setTotalPrice(priceData?.DEPARTED?.ttPrem));
      dispatch(setSelectedPlanName(PLAN_TYPES.DEPARTED));
    } else {
      dispatch(setTotalPrice(priceData?.[activePlan]?.ttPrem));
      dispatch(setSelectedPlanName(PLAN_TYPES[activePlan] || PLAN_TYPES.BASIC));
    }
  }, [activePlan, priceData, dispatch, hasDeparted]);

  useEffect(() => {
    if (userInfo.dateOfBirth) {
      const birthYear = parseInt(userInfo.dateOfBirth.substring(0, 4), 10);
      const currentYear = new Date().getFullYear();
      setUserAge(currentYear - birthYear);
    }
  }, [userInfo.dateOfBirth]);

  const quotations = useMemo(() => {
    if (!priceData) return [];
    if (hasDeparted) {
      const contractorData = priceData.DEPARTED.opapiGnrCoprCtrInspeInfCbcVo[0];
      return [
        {
          opapiGnrCoprCtrQuotCovInfCbcVo:
            contractorData.opapiGnrCoprCtrQuotCovInfCbcVo,
        },
      ];
    }
    return [
      {
        opapiGnrCoprCtrQuotCovInfCbcVo:
          priceData[activePlan]?.opapiGnrCoprCtrInspeInfCbcVo[0]
            ?.opapiGnrCoprCtrQuotCovInfCbcVo || [],
      },
    ];
  }, [priceData, activePlan, hasDeparted]);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    try {
      return formatKoreanDate(dateString);
    } catch (error) {
      console.error("날짜 변환 오류:", error);
      return "-";
    }
  };

  const handlePlanButtonClick = (dataKey, planName) => {
    setActivePlan(dataKey);
    dispatch(setSelectedPlanName(`${planName}`));
    dispatch(selectData({ name: planName }));
  };

  const renderInsuredPersons = () => (
    <div className={styles.dataFlexbox}>
      <p>가입자</p>
      <div className="flex flex-col">
        <span>
          {getGenderString(userInfo.gender)} (만 {userAge}세)
          {companions.length > 0 ? ` 외 ${companions.length}명` : ""}
        </span>
      </div>
    </div>
  );

  const renderQuotations = () => (
    <div className={styles.dataListWrap}>
      {quotations.map((personData, personIndex) => (
        <div key={personIndex} className={styles.personQuotations}>
          {personData.opapiGnrCoprCtrQuotCovInfCbcVo.map((item, index) => (
            <div key={`${personIndex}-${index}`} className={styles.dataList}>
              <p
                className={`${styles.datalist_text} ${
                  hasDeparted ? styles.hasDeparted : ""
                }`}
              >
                특약
              </p>
              <div className={styles.priceDatas}>
                <p>{item.covNm}</p>
              </div>
              <div className={styles.imagePrice}>
                <p>{formatKoreanPrice(parseInt(item.insdAmt))}원</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const nextButton = () => {
    if (isButtonActive) {
      navigate("/signup/member");
    }
  };

  return (
    <div className={styles.confirmContents}>
      <div className={styles.confirmWrap}>
        <div className={styles.headerSection}>
          <h3>
            {hasDeparted ? (
              <>
                <span className={styles.hasDeparteds}>출국 후</span> 보험 가입
                내용입니다
              </>
            ) : (
              <>
                선택하신 <span className={styles.highlight}>보장내용</span>과{" "}
                <span className={styles.highlight}>보험료</span>를<br />{" "}
                안내해드립니다.
              </>
            )}
          </h3>

          {!hasDeparted &&
            isFromCsvUpload &&
            pdfBlobs?.[currentPlanForPDF]?.size > 0 && (
              <div className={styles.pdfBtnWrap}>
                <button
                  onClick={handleDownloadClick}
                  className={styles.pdfDownloadButton}
                >
                  <img src={downLoad} alt="downLoad" />
                  <span>견적서 다운로드</span>
                </button>
              </div>
            )}
        </div>

        <section className={hasDeparted ? styles.on : styles.section}>
          <div className={styles.sectionWrap}>
            <div className={styles.sectionWrap_title}>
              <div className={styles.ContentsImgWrap}>
                <img
                  src={
                    activePlan === "RECOMMEND"
                      ? "/images/Rec_icon.png"
                      : activePlan === "PREMIUM"
                      ? "/images/Lux_icon.png"
                      : activePlan === "BASIC"
                      ? "/images/Benefit_icon.png"
                      : activePlan === "LITE"
                      ? "/images/Ultra_icon.png"
                      : "/images/airport.png"
                  }
                  alt={`${PLAN_TYPES[activePlan]} 로고`}
                  className={styles.ContentsImg}
                />
              </div>
              <p>
                {hasDeparted ? PLAN_TYPES.DEPARTED : PLAN_TYPES[activePlan]}
              </p>
            </div>
            <div className={styles.section_DataContents}>
              <div className={styles.dataContext}>
                <p>가입내용</p>
                <span
                  className={`${styles.modifyButton} ${
                    hasDeparted ? styles.hasDeparted : ""
                  }`}
                  onClick={() => setIsModalOpen(true)}
                >
                  수정하기
                </span>
              </div>
              <div className={styles.section_DataContentsWrap}>
                <div className={styles.dataFlexbox}>
                  <p>출발 일정일</p>
                  <span>{formatDate(userInfo.startDate)}</span>
                </div>
                <div className={styles.dataFlexbox}>
                  <p>도착 일정일</p>
                  <span>{formatDate(userInfo.endDate)}</span>
                </div>
                {renderInsuredPersons()}
                <div className={styles.section_cost}>
                  <div className={styles.section_costWrap}>
                    <p className={hasDeparted ? styles.hasDeparted : ""}>
                      총 보험료
                    </p>
                    <span className={hasDeparted ? styles.hasDeparted : ""}>
                      {hasDeparted
                        ? formatPrice(
                            parseInt(priceData?.DEPARTED?.ttPrem || 0)
                          )
                        : formatPrice(parseInt(totalPrice || 0))}
                      원
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <p className={styles.announce}>
          <span>*</span> 만 15세 미만 고객은 사망보장이 제외된 보장 내용으로
          가입됨을 안내드립니다.
        </p>

        {!hasDeparted && (
          <section className={styles.filterBtn}>
            <div className={styles.filterBtnWrap}>
              <ul>
                {PLAN_LIST.map((plan, index) => (
                  <li
                    key={index}
                    className={`cursor-pointer w-full mx-auto ${
                      plan.dataKey === activePlan ? styles.active : ""
                    }`}
                    onClick={() =>
                      handlePlanButtonClick(plan.dataKey, plan.name)
                    }
                  >
                    {plan.name.replace(" 플랜", "")}
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}

        <div className="w-full">{renderQuotations()}</div>
        <ConfirmPDF />
        <ModifyModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        <div className={styles.buttonWrap}>
          <div className={styles.buttonWrap_bg}></div>
          <div className={styles.buttonWrap_line}></div>
          <Button
            buttonText="보험 가입하기"
            onClick={nextButton}
            disabled={!isButtonActive}
          />
        </div>
      </div>
    </div>
  );
}

export default Confirm;
