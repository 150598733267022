import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTokenValidation, setCookie, setMrzUser } from "../redux/store";
import Loading from "../components/modal/Loading";

const PrivateRoute = ({ element }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isMrzUser, setIsMrzUser] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentPath = location.pathname;
  const currentSearch = new URLSearchParams(location.search);
  const redirectPath = currentSearch.get("redirect");

  // 리다이렉트가 필요없는 public 라우트들
  const noRedirectPaths = ["/"];

  useEffect(() => {
    const validateToken = async () => {
      try {
        setIsLoading(true); // 로딩 상태 시작
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/trip-api/auth/checkToken`,
          {
            method: "GET",
            credentials: "include",
            redirect: "follow",
          }
        );

        if (!response.ok) {
          dispatch(setTokenValidation(false));
          return;
        }

        const result = await response.json();
        dispatch(setCookie(result));
        dispatch(setMrzUser(result.mrzUser));
        dispatch(setTokenValidation(result.success));
        setIsMrzUser(result.mrzUser);
      } catch (error) {
        console.error("Token validation failed:", error);
        dispatch(setTokenValidation(false));
      } finally {
        setIsLoading(false);
      }
    };

    validateToken();
  }, [currentPath]);

  // public 라우트는 인증 상태와 관계없이 렌더링
  if (noRedirectPaths.includes(currentPath)) {
    return element;
  }

  // 로딩 중에는 로딩 컴포넌트 표시
  if (isLoading) {
    return <Loading />;
  }

  // 통합인증여부에 따른 리다이렉트 처리
  if (currentPath === "/claimMembersIntro" && !isMrzUser) {
    const redirectUrl = `/combine?redirect=${encodeURIComponent(
      currentPath + "?" + currentSearch
    )}`;
    return <Navigate to={redirectUrl} state={{ from: location }} />;
  }

  if (currentPath === "/emergencySupport" && !isMrzUser) {
    const redirectUrl = `/combine?redirect=${encodeURIComponent(
      currentPath + "?" + currentSearch
    )}`;
    return <Navigate to={redirectUrl} state={{ from: location }} />;
  }

  if (currentPath === "/combine" && isMrzUser) {
    return <Navigate to={redirectPath || "/"} state={{ from: location }} />;
  }

  // 로그인이 필요한 경우
  if (!isAuthenticated) {
      const loginRedirect = `/login?redirect=${encodeURIComponent(
      currentPath + "?" + currentSearch
    )}`;
    return <Navigate to={loginRedirect} state={{ from: location }} />;
  }

  // if (redirectPath) {
  //   return <Navigate to={redirectPath} state={{ from: location }} />;
  // }

  // 인증된 경우 요청된 컴포넌트 렌더링
  return element;
};

export default PrivateRoute;
