import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";

//PDF slice 정의
const pdfSlice = createSlice({
  name: "pdf",
  initialState: {
    pdfBlobs: {},
    isFromCsvUpload: false,
  },
  reducers: {
    setPDFBlobs: (state, action) => {
      state.pdfBlobs = action.payload;
    },
    //초기화화
    clearPDFBlobs: (state) => {
      state.pdfBlobs = {};
    },
    //상태값
    setIsFromCsvUpload: (state, action) => {
      state.isFromCsvUpload = action.payload;
    },
  },
});

// hasDeparted slice 정의
const hasDepartedSlice = createSlice({
  name: "hasDeparted",
  initialState: {
    isDeparted: false, // false 대신 빈 문자열로 초기화
  },
  reducers: {
    setHasDeparted(state, action) {
      state.isDeparted = action.payload;
    },
  },
});

// PersonalInfo slice 정의
const personalInfoSlice = createSlice({
  name: "personalInfo",
  initialState: {
    koreanName: "",
    englishName: "",
    email: "",
    phoneNumber: "",
  },
  reducers: {
    setKoreanName(state, action) {
      state.koreanName = action.payload;
    },
    setEnglishName(state, action) {
      state.englishName = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
  },
});

// Plan slice 정의
const planSlice = createSlice({
  name: "plan",
  initialState: {
    selectedData: null,
    selectedPlanName: null,
  },
  reducers: {
    selectData(state, action) {
      state.selectedData = action.payload;
    },
    setSelectedPlanName(state, action) {
      state.selectedPlanName = action.payload;
    },
  },
});

// Calendar slice 정의
const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    selectedStartDate: null,
    selectedEndDate: null,
  },
  reducers: {
    selectStartDate(state, action) {
      state.selectedStartDate = action.payload;
    },
    selectEndDate(state, action) {
      state.selectedEndDate = action.payload;
    },
  },
});

// User slice 정의
const userSlice = createSlice({
  name: "user",
  initialState: {
    gender: "",
    dateOfBirth: "",
  },
  reducers: {
    setGender(state, action) {
      state.gender = action.payload;
    },
    setDateOfBirth(state, action) {
      state.dateOfBirth = action.payload;
    },
  },
});

// Companions slice 정의
const companionsSlice = createSlice({
  name: "companions",
  initialState: [],
  reducers: {
    setCompanions(state, action) {
      return action.payload;
    },
    addCompanion(state, action) {
      state.push(action.payload);
    },
    updateCompanionGender(state, action) {
      const { index, gender } = action.payload;
      state[index].gender = gender;
    },
    updateCompanionDateOfBirth(state, action) {
      const { index, dateOfBirth } = action.payload;
      state[index].dateOfBirth = dateOfBirth;
    },
    deleteCompanion(state, action) {
      state.splice(action.payload, 1);
    },
  },
});

// UserForm slice 정의
const userFormSlice = createSlice({
  name: "userForm",
  initialState: {
    name: "",
    englishName: "",
    phoneNumber: "",
    email: "",
  },
  reducers: {
    setUserFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearUserFormData: () => {
      return {
        name: "",
        englishName: "",
        phoneNumber: "",
        email: "",
      };
    },
  },
});

// CompanionForm slice 정의
const companionFormSlice = createSlice({
  name: "companionForm",
  initialState: [],
  reducers: {
    setCompanionFormData: (state, action) => {
      return action.payload;
    },
    clearCompanionFormData: () => {
      return [];
    },
  },
});

// Country slice 정의
const countrySlice = createSlice({
  name: "country",
  initialState: {
    selectedCountryData: null,
    recommendedCountryData: null,
  },
  reducers: {
    setSelectedCountryData(state, action) {
      state.selectedCountryData = action.payload;
    },
    setRecommendedCountryData(state, action) {
      state.recommendedCountryData = action.payload;
    },
    clearCountryData(state) {
      state.selectedCountryData = null;
      state.recommendedCountryData = null;
    },
  },
});

// TotalPrice slice 정의
const totalPriceSlice = createSlice({
  name: "totalPrice",
  initialState: {
    totalPrice: null,
  },
  reducers: {
    setTotalPrice(state, action) {
      state.totalPrice = action.payload;
    },
  },
});

// PriceData slice 정의
const priceDataSlice = createSlice({
  name: "priceData",
  initialState: {
    priceData: null,
  },
  reducers: {
    setPriceData(state, action) {
      state.priceData = action.payload;
    },
  },
});

// Auth slice 정의
const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    isTokenValidated: null,
    isAuthenticated: null,
  },
  reducers: {
    login(state, action) {
      state.token = action.payload;
      state.isAuthenticated = true;
    },
    logout(state) {
      state.token = null;
      state.isAuthenticated = false;
      state.isTokenValidated = false;
    },
    setTokenValidation(state, action) {
      state.isTokenValidated = true;
      state.isAuthenticated = action.payload;
    },
  },
});

// Claim slice 정의
const claimSlice = createSlice({
  name: "claim",
  initialState: {
    birthSecondPart: "",
    status: null,
    message: null,
  },
  reducers: {
    setBirthSecondPart(state, action) {
      state.birthSecondPart = action.payload;
    },
  },
});

// MembersData slice 정의
const membersDataSlice = createSlice({
  name: "members",
  initialState: {
    members: "",
  },
  reducers: {
    setMembersData(state, action) {
      state.members = action.payload;
    },
  },
});

// Insurance slice 정의
const insuranceSlice = createSlice({
  name: "insurance",
  initialState: {
    insurances: {
      Insurances: [],
      name: "",
    },
  },
  reducers: {
    setInsurance(state, action) {
      state.insurances = action.payload;
    },
  },
});

// Cookie slice 정의
const cookieSlice = createSlice({
  name: "cookie",
  initialState: {
    cookie: [],
    mrzUser: false, // mrzUser를 위한 초기값 추가
  },
  reducers: {
    setCookie(state, action) {
      state.cookie = action.payload;
    },
    setMrzUser(state, action) {
      state.mrzUser = action.payload;
    },
  },
});

// Combine all reducers into rootReducer
const rootReducer = combineReducers({
  hasDeparted: hasDepartedSlice.reducer,
  plan: planSlice.reducer,
  calendar: calendarSlice.reducer,
  user: userSlice.reducer,
  companions: companionsSlice.reducer,
  country: countrySlice.reducer,
  totalPrice: totalPriceSlice.reducer,
  priceData: priceDataSlice.reducer,
  auth: authSlice.reducer,
  claim: claimSlice.reducer,
  members: membersDataSlice.reducer,
  insurance: insuranceSlice.reducer,
  cookie: cookieSlice.reducer,
  personalInfo: personalInfoSlice.reducer,
  userForm: userFormSlice.reducer,
  companionForm: companionFormSlice.reducer,
  pdf: pdfSlice.reducer,
});

// 기본 초기값 정의
const defaultState = {
  hasDeparted: { isDeparted: "" },
  plan: { selectedData: null, selectedPlanName: null },
  calendar: { selectedStartDate: null, selectedEndDate: null },
  user: { gender: "", dateOfBirth: "" },
  companions: [],
  country: { selectedCountryData: null, recommendedCountryData: null },
  totalPrice: { totalPrice: null },
  priceData: { priceData: null },
  insurance: {
    insurances: {
      Insurances: [],
      name: "",
    },
  },
  members: { members: "" },
  cookie: { cookie: [] },
  personalInfo: { koreanName: "", englishName: "", email: "", phoneNumber: "" },
  userForm: { name: "", englishName: "", phoneNumber: "", email: "" },
  companionForm: [],
  auth: {
    token: null,
    isTokenValidated: false,
    isAuthenticated: false,
  },
};

// preloadedState 설정
const preloadedState = (() => {
  const savedState = JSON.parse(sessionStorage.getItem("reduxState") || "{}");
  // 기본값과 저장된 값을 병합
  return {
    ...defaultState, // 기본값 먼저 설정
    ...savedState, // 저장된 값으로 덮어쓰기
  };
})();

// Remove unexpected keys if any
const expectedKeys = Object.keys(defaultState);

Object.keys(preloadedState).forEach((key) => {
  if (!expectedKeys.includes(key)) {
    delete preloadedState[key];
  }
});

// Create the Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Blob 데이터가 포함된 action과 state 경로를 무시
        ignoredActions: ["pdf/setPDFBlobs"],
        ignoredPaths: ["pdf.pdfBlobs"],
      },
    }),
  preloadedState,
});

// Save state to sessionStorage (excluding auth and claim slices)
store.subscribe(() => {
  const state = store.getState();
  const { claim, ...stateToSave } = state;
  sessionStorage.setItem("reduxState", JSON.stringify(stateToSave));
});

// Export actions
export const {
  setHasDeparted,
  selectData,
  setSelectedPlanName,
  selectStartDate,
  selectEndDate,
  setGender,
  setDateOfBirth,
  setCompanions,
  addCompanion,
  updateCompanionGender,
  updateCompanionDateOfBirth,
  deleteCompanion,
  setSelectedCountryData,
  setRecommendedCountryData,
  clearCountryData,
  setMembersData,
  login,
  logout,
  setInsurance,
  setClaimStatus,
  setCookie,
  setMrzUser,
  setUserFormData,
  clearUserFormData,
  setCompanionFormData,
  clearCompanionFormData,
  setTokenValidation,
} = {
  ...hasDepartedSlice.actions,
  ...planSlice.actions,
  ...calendarSlice.actions,
  ...userSlice.actions,
  ...companionsSlice.actions,
  ...countrySlice.actions,
  ...authSlice.actions,
  ...membersDataSlice.actions,
  ...insuranceSlice.actions,
  ...claimSlice.actions,
  ...cookieSlice.actions,
  ...userFormSlice.actions,
  ...companionFormSlice.actions,
};

// personalInfo 액션들 별도로 export
export const { setKoreanName, setEnglishName, setEmail, setPhoneNumber } =
  personalInfoSlice.actions;
export const { setPriceData } = priceDataSlice.actions;
export const { setTotalPrice } = totalPriceSlice.actions;
export const { setBirthSecondPart } = claimSlice.actions;
export const { setPDFBlobs, clearPDFBlobs, setIsFromCsvUpload } =
  pdfSlice.actions;

export default store;
