import React from "react";
import LeftArrow from "../../assets/commonLeftArrow.svg";
import { useNavigate } from "react-router-dom";
import styles from "../../css/Claim/claimHeader.module.css";

function ClaimSubHeaders({ titleText }) {
  const navigate = useNavigate();
  const backHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className={styles.HeaderWrap}>
        <div className={styles.HeaderContents}>
          <div className={styles.ContentsWrap}>
            <img src={LeftArrow} alt="LeftArrow" onClick={backHome} />
            <p>{titleText}</p>
            <div className="w-[24px]"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimSubHeaders;
