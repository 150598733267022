import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DateSelectModal from "../modal/DateSelectModal";
import styles from "../../css/calculationProcessCSS/Insert.module.css";
import { selectEndDate, selectStartDate } from "../../redux/store";

function InsertDate({ faRetrustData }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("start");
  const endInputRef = useRef(null);
  const hasDeparted = useSelector((state) => state.hasDeparted.isDeparted);
  const startDate = useSelector((state) => state.calendar.selectedStartDate);
  const endDate = useSelector((state) => state.calendar.selectedEndDate);

  useEffect(() => {
    if (hasDeparted) {
      // 출국 후인 경우 현재 날짜를 출발일로 자동 설정
      const today = moment().format("YYYYMMDD");
      dispatch(selectStartDate(today));
    } else if (faRetrustData && faRetrustData.startDate) {
      dispatch(selectStartDate(faRetrustData.startDate));
    }
  }, [hasDeparted, faRetrustData, dispatch]);

  useEffect(() => {
    if (faRetrustData && faRetrustData.endDate) {
      dispatch(selectEndDate(faRetrustData.endDate));
    }
  }, [faRetrustData, dispatch]);

  const handleModalOpen = (modalType) => {
    return () => {
      if (hasDeparted && modalType === "start") {
        // 출국 후인 경우 출발일 선택 불가
        return;
      }
      setModalType(modalType);
      setShowModal(true);
    };
  };

  const formatDate = (date) => {
    if (!date) return "";
    return moment(date, "YYYYMMDD").isValid()
      ? moment(date, "YYYYMMDD").format("YYYY-MM-DD")
      : moment(date).format("YYYY-MM-DD");
  };

  const handleApply = () => {
    if (modalType === "start") {
      setShowModal(false);
      if (!hasDeparted) {
        // 출국 전인 경우만 도착일 선택 모달 자동 오픈
        setModalType("end");
        setShowModal(true);
      }
      if (moment(startDate).isAfter(moment(endDate))) {
        endInputRef.current.value = "";
      }
    } else {
      setShowModal(false);
    }
  };

  return (
    <>
      <div className={styles.calendarBox}>
        <p className={styles.calendars}>여행 일정</p>
        <div className={styles.InputFlex}>
          <input
            value={formatDate(startDate)}
            placeholder={hasDeparted ? "오늘 날짜" : "출발일"}
            className={`${styles.InputContent} ${
              hasDeparted ? "bg-gray-100" : ""
            }`}
            onClick={handleModalOpen("start")}
            readOnly
            disabled={hasDeparted}
          />
          <input
            ref={endInputRef}
            value={formatDate(endDate)}
            placeholder="도착일"
            className={styles.InputContent}
            onClick={handleModalOpen("end")}
            readOnly
          />
        </div>
      </div>
      <DateSelectModal
        isOpen={showModal}
        onClose={handleApply}
        isStartModal={modalType === "start"}
        startDate={startDate}
        faRetrustData={faRetrustData}
        hasDeparted={hasDeparted}
      />
    </>
  );
}

export default InsertDate;
