import React from "react";
import { Route, Routes } from "react-router-dom";
import ClaimRequiredDoc from "../claimPages/ClaimRquiredDoc";
import ClaimIntro from "./ClaimIntro";

import ClaimReferral from "./ClaimReferral";
import ClaimRevocation from "./ClaimRevocation";
import ClaimEntryAndAgreement from "./ClaimEntryAndAgreement";
import ClaimDocuments from "./ClaimDocuments";
import ClaimConfirm from "./ClaimConfirm";
import NotFoundPage from "../NotFoundPage";
import PaymentStatement from "./PaymentStatement";
import ClaimContractInfo from "./ClaimContractInfo";
import ClaimContractDetail from "./ClaimContractDetail";
// import ClaimMembers from "./ClaimMembers";
import ClaimMembersInfo from "./ClaimMembersInfo";
import useScrollToTop from "../../hooks/useNavigateWithScrollToTop";
// import ClaimLogin from "./ClaimLogin";
import ClaimRevocationDetail from "./ClaimRevocationDetail";
import ClaimCombine from "./ClaimCombine";
import PrivateRoute from "../../hooks/PrivateRoute";
import ClaimRevocationAll from "./ClaimRevocationAll";
import ClaimExtendDate from "./ClaimExtendDate";
import ClaimExtendDateDetails from "./ClaimExtendDateDetails";
import ClaimMemberIntro from "./ClaimMemberIntro";
import ClaimExtendSelectDate from "./ClaimExtendSelectDate";
import ClaimExtendCostChk from "./ClaimExtendCostChk";
import EmergencySupport from "./EmergencySupport";
import ClaimReferralDetail from "./ClaimReferralDetail";
import ClaimLogin from "./ClaimLogin";
import ClaimMain from "./ClaimMain";
import ClaimFAQ from "./ClaimFAQ";

const ClaimProcessRouter = () => {
  useScrollToTop();

  return (
    <Routes>
      <Route path="/" element={<PrivateRoute element={<ClaimMain />} />} />
      <Route path="/login" element={<ClaimIntro />} />
      <Route path="/claimFAQ" element={<ClaimFAQ />} />
      <Route path="/claimLogin" element={<ClaimLogin />} />
      <Route path="/emergencySupport" element={<EmergencySupport />} />
      <Route
        path="/combine"
        element={<PrivateRoute element={<ClaimCombine />} />}
      />
      <Route
        path="/claimReferral"
        element={<PrivateRoute element={<ClaimReferral />} />}
      />
      <Route
        path="/claimReferral/:card_id"
        element={<PrivateRoute element={<ClaimReferralDetail />} />}
      />
      <Route
        path="/claimRevocation"
        element={<PrivateRoute element={<ClaimRevocation />} />}
      />
      <Route
        path="/claimRevocationAll"
        element={<PrivateRoute element={<ClaimRevocationAll />} />}
      />
      <Route
        path="/claimRevocation/:id"
        element={<PrivateRoute element={<ClaimRevocationDetail />} />}
      />
      <Route
        path="/claimAgree"
        element={<PrivateRoute element={<ClaimEntryAndAgreement />} />}
      />
      <Route
        path="/claimContractInfo"
        element={<PrivateRoute element={<ClaimContractInfo />} />}
      />
      <Route
        path="/claimContractInfo/:id"
        element={<PrivateRoute element={<ClaimContractDetail />} />}
      />
      <Route
        path="/claimDocuments"
        element={<PrivateRoute element={<ClaimDocuments />} />}
      />
      <Route
        path="/required"
        element={<PrivateRoute element={<ClaimRequiredDoc />} />}
      />
      <Route
        path="/claimConfirm"
        element={<PrivateRoute element={<ClaimConfirm />} />}
      />
      <Route
        path="/claimPaymentStatement/:clmId"
        element={<PrivateRoute element={<PaymentStatement />} />}
      />
      <Route
        path="/claimMembersIntro"
        element={<PrivateRoute element={<ClaimMemberIntro />} />}
      />
      <Route
        path="/claimMembersInfo"
        element={<PrivateRoute element={<ClaimMembersInfo />} />}
      />

      <Route
        path="/claimExtendDate"
        element={<PrivateRoute element={<ClaimExtendDate />} />}
      />
      <Route
        path="/claimExtendDate/:moid"
        element={<PrivateRoute element={<ClaimExtendDateDetails />} />}
      />
      <Route
        path="/claimExtendSelectDate"
        element={<PrivateRoute element={<ClaimExtendSelectDate />} />}
      />
      <Route
        path="/claimExtendCostChk"
        element={<PrivateRoute element={<ClaimExtendCostChk />} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default ClaimProcessRouter;
