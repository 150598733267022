import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../redux/store"; // login 액션을 올바르게 가져옴
import claimintroClip from "../../assets/claimintroClip.svg";
import styles from "../../css/nice/nice.module.css";
import QRCodeModal from "../modal/QRCodeModal";
import ErrorModal from "../modal/ErrorModal";

function ClipLogin() {
  const location = useLocation(); // useLocation 훅 사용
  const dispatch = useDispatch(); // useDispatch 훅 추가
  const [showModal, setShowModal] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState(null);
  const [errorData, setErrorData] = useState("");
  const [popupWindow, setPopupWindow] = useState(null);
  const [klipData, setKlipData] = useState(null);
  const [intervalId, setIntervalId] = useState(null);  // interval ID를 저장하기 위한 state 추가

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        )
      );
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);

    // 페이지 로드시 Klip 데이터 가져오기
    const fetchKlipData = async () => {
      try {
        const response = await fetch("/auth/klip", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          setKlipData(result.data);
        } else {
          throw new Error("Failed to fetch Klip data");
        }
      } catch (error) {
        console.error("Error fetching Klip data:", error);
        setError("Klip 데이터를 가져오는데 실패했습니다.");
      }
    };

    fetchKlipData();

    return () => {
      window.removeEventListener("resize", checkMobile);
    }
  }, []);

  const checkLoginStatus = async (request_id) => {
    try {
      const addressResponse = await fetch(
        `/auth/klip_login?requestId=${request_id}`,
        {
          method: "GET",
        }
      );
      const addressResult = await addressResponse.json();
      console.log(addressResult);
      if (addressResult.success) {
        // 로그인 성공시 팝업 창과 인터벌 정리
        if (popupWindow) {
          if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
          popupWindow.close();
          setPopupWindow(null);
        }

        dispatch(login(JSON.stringify(addressResult)));
        setShowModal(false);

        const queryParams = new URLSearchParams(location.search);
        const redirectPath = queryParams.get("redirect") || "/";
        console.log("이전 경로:", redirectPath);
        window.location.href = redirectPath;
      } else {
        // 로그인 실패 시 에러 처리
        setError(true);
        setErrorData(addressResult.message);
        setShowModal(false);

        // 팝업 창이 열려있다면 닫기
        if (popupWindow) {
          popupWindow.close();
          setPopupWindow(null);
        }
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
    } catch (error) {
      console.error("Error checking login status:", error);
      setError("로그인 상태 확인 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const onCloseModal = () => {
    setError(false);
  };

  const openPopupWindow = (url) => {
    // 기존 팝업과 인터벌이 있다면 정리
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIntervalId(null);
    setPopupWindow(null);

    const width = window.innerWidth * 0.9;
    const height = window.innerHeight * 0.9;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      url,
      "KlipLogin",
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setPopupWindow(popup);

    // 팝업 창 감지 인터벌 설정 및 ID 저장
    const newIntervalId = setInterval(() => {
      if (popup.closed) {
        clearInterval(newIntervalId);
        setIntervalId(null);
        setPopupWindow(null);
      }
    }, 1000);  // 1초마다 체크하도록 변경

    setIntervalId(newIntervalId);

    // 5초 후 자동 정리
    setTimeout(() => {
      if (popup && !popup.closed) {
        popup.close();
      }
      clearInterval(newIntervalId);
      setIntervalId(null);
      setPopupWindow(null);
    }, 5 * 1000);
  };

  const handleNFTReceive = async () => {
    setError(null);
    try {
      const { request_id, deep_link } = klipData;
      if (isMobile) {
        openPopupWindow(deep_link);
      } else {
        setQrCodeData(deep_link);
        setShowModal(true);
      }
      checkLoginStatus(request_id);
    } catch (error) {
      console.error("Error in handleNFTReceive:", error);
      setError("로그인을 시작하는 데 문제가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  return (
    <>
      <div className={styles.ClipLoginBtn} onClick={handleNFTReceive}>
        <img src={claimintroClip} alt="클립 로그인" />
        <p>클립으로 로그인</p>
      </div>
      {error && (
        <p className={styles.errorMessage} role="alert">
          {error}
        </p>
      )}
      {showModal && (
        <QRCodeModal
          onClose={() => setShowModal(false)}
          qrCodeData={qrCodeData}
        />
      )}
      {error && <ErrorModal message={`${errorData}`} onClose={onCloseModal} />}
    </>
  );
}

export default ClipLogin;
