import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ClaimHeader from "../../components/claimComponents/ClaimHeader";
import styles from "../../css/Claim/claimRevocationDetail.module.css";
import ClaimDeleteModal from "../../components/modal/ClaimDeleteModal";
import { useSelector } from "react-redux";
import ClaimButton from "../../components/common/ClaimButton";

function ClaimRevocationDetail() {
  const { id } = useParams();
  const tokenData = useSelector((state) => state.insurance.insurances);

  const selectedItem = tokenData.Insurances.find(
    (item) => item.Contract.id === parseInt(id)
  );

  const formatNumberWithCommas = (numberString) => {
    if (!numberString) return "0";
    const number = parseInt(numberString);
    return number.toLocaleString();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Contract가 있는지 확인 후 값을 렌더링
  const insuranceStartDate = selectedItem?.Contract?.insurance_start_date || "";
  const insuranceEndDate = selectedItem?.Contract?.insurance_end_date || "";
  const insurancePremium = selectedItem?.insurance_premium || "";
  const totalPremium = selectedItem?.Contract?.total_premium || "";

  return (
    <>
      <ClaimHeader titleText="개시 전 취소 청약 철회" />
      <div className={styles.Wrapper}>
        <h3 className={styles.title}>
          여행 보험/장기체류보험
          <br /> 가입내역확인
        </h3>
        <section className={styles.section}>
          <div className={styles.sectionContents}>
            <div className={styles.sectionContentsWrap}>
              <p className={styles.title}>해외여행 실손의료보험</p>
              <div className={styles.boundary}></div>
              <div className={styles.userInfoListWrap}>
                <div className={styles.userInfoContents}>
                  <ul>
                    <li>
                      <p>증권번호</p>
                      <span>{process.env.REACT_APP_POLICY_NO}</span>
                    </li>
                    <li>
                      <p>계약자</p>
                      <span>{selectedItem.Contract.User?.contractor_name}</span>
                    </li>
                    <li>
                      <p>피보험자</p>
                      <span>{tokenData?.name}</span>
                    </li>
                    <li>
                      <p>여행기간</p>
                      <span>{`${insuranceStartDate} - ${insuranceEndDate}`}</span>
                    </li>
                    <li>
                      <p>보험료</p>
                      <span>{`${formatNumberWithCommas(
                        insurancePremium
                      )}원`}</span>
                    </li>
                  </ul>
                  <div className={styles.PriceConents}>
                    <div className={styles.PriceConentsWrap}>
                      <p>환금보험료</p>
                      <span>{`${formatNumberWithCommas(
                        totalPremium
                      )}원`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ClaimButton buttonText="여행보험 가입 취소" onClick={handleOpenModal} />
        </section>
      </div>
      <ClaimDeleteModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        selectedItem={selectedItem}
      />
    </>
  );
}

export default ClaimRevocationDetail;
