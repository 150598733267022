import React, { useState, useEffect } from "react";
import styles from "../../css/Claim/claimMembersInfo.module.css";
import commonDownArrow from "../../assets/commonDownArrow.svg";
import ClaimCalendar from "../../components/claimComponents/ClaimCalendar";
import CustomInput from "../../components/common/CustomInput";
import ClaimMemberData from "../../api/ClaimMemberData.json";

const clmTpCdData = ClaimMemberData.clmTpCd;
const acdCausLctgCd = ClaimMemberData.acdCausLctgCd;

function AccidentInfoSection({
  text,
  handleChange,
  phoneNumber,
  setPhoneNumber,
  setSelectedValues,
  selectedValues,
  error,
  setError,
  setAccidentDate,
  setAccidentDateKey,
  setClaimTypeKey,
  isButtonDisabled,
  selectedClaimDate,
  encryptedRRN,
  setSelectedContractId,
}) {
  const maxLength = 1000;
  const [isOpen, setIsOpen] = useState(false);
  const [subAccordionOpen, setSubAccordionOpen] = useState(false);
  const claimableContracts = selectedClaimDate?.data?.claimableContracts;
  const [ableDate, setAbleDate] = useState([]);
  const [inputTouched, setInputTouched] = useState({
    phoneNumber: false,
  });

  useEffect(() => {
    if (claimableContracts) {
      const dateRanges = claimableContracts.flatMap((contract) => {
        const startDate = new Date(contract.insurance_start_date);
        const endDate = new Date(contract.insurance_end_date);
        const dates = [];

        for (
          let currentDate = new Date(startDate);
          currentDate <= endDate;
          currentDate.setDate(currentDate.getDate() + 1)
        ) {
          dates.push(new Date(currentDate).toISOString().split("T")[0]);
        }

        return dates;
      });

      setAbleDate(dateRanges);
    }
  }, [claimableContracts]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setSelectedValues((prev) => ({
      ...prev,
      subClmTpCd: null,
    }));
    setSubAccordionOpen(false);
  };

  const toggleSubAccordion = () => {
    setSubAccordionOpen(!subAccordionOpen);
  };

  const selectClmTpCd = (clmTpCdKey) => {
    const clmTpCdValue = clmTpCdData[clmTpCdKey];
    setSelectedValues((prev) => ({
      ...prev,
      clmTpCd: clmTpCdValue,
    }));
    setClaimTypeKey(clmTpCdKey);
    setIsOpen(false);
  };

  const selectSubClmTpCd = (subClmTpCdKey) => {
    const subClmTpCdValue =
      acdCausLctgCd[selectedValues.clmTpCd][subClmTpCdKey];
    setSelectedValues((prev) => ({
      ...prev,
      subClmTpCd: subClmTpCdValue,
    }));
    setAccidentDateKey(subClmTpCdKey);
    setSubAccordionOpen(false);
  };

  const handlePhoneNumberChange = (value) => {
    value = value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/-{1,2}$/g, "");
    setPhoneNumber(value);
    setInputTouched({ ...inputTouched, phoneNumber: true });

    if (value.trim() === "") {
      setError({ ...error, phoneNumber: "" });
    }
  };

  const handleDateSelect = (date) => {
    setAccidentDate(date);
  };

  const shouldShowError = (field) => {
    return (
      inputTouched[field] &&
      error[field] &&
      error[field] !== "" &&
      phoneNumber.trim() !== "" &&
      isButtonDisabled
    );
  };

  return (
    <div className={styles.section_2}>
      <div className={styles.sectionContentsWrap_Title}>
        <span>2</span>
        <p>사고 정보 입력</p>
      </div>
      <div className={styles.accidentInfoWrap}>
        <div className={styles.accidentInfo}>
          <div className={styles.accidentInfoSlect}>
            <p>피보험자 선택</p>
            <div className={styles.accidentInfoSlectBox}>
              <div className={styles.accidentInfoSlectBoxWrap}>
                <div
                  className={styles.accidentInfoSlectBox_AccodianBtn}
                  onClick={toggleAccordion}
                >
                  {selectedValues.clmTpCd ? (
                    <p>{selectedValues.clmTpCd}</p>
                  ) : (
                    <span>사고종류에 대해 선택해주세요.</span>
                  )}
                  <img src={commonDownArrow} alt="downArrow" />
                </div>
                {isOpen && (
                  <div className={styles.accidentInfoSlectBox}>
                    <div
                      className={styles.accidentInfoSlectBox_AccodianContents}
                    >
                      <ul>
                        {Object.keys(clmTpCdData).map((key) => (
                          <li
                            key={key}
                            className={`${styles.accidentInfoSlectItem} ${
                              selectedValues.clmTpCd === clmTpCdData[key]
                                ? styles.selected
                                : ""
                            }`}
                            onClick={() => selectClmTpCd(key)}
                          >
                            {clmTpCdData[key]}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {selectedValues.clmTpCd && (
            <div
              className={`${styles.accidentInfoSlect} ${
                !isButtonDisabled ? styles.disabled : ""
              }`}
            >
              <p>사고 원인선택</p>
              <div className={`${styles.accidentInfoSlectBox} `}>
                <div className={styles.accidentInfoSlectBoxWrap}>
                  <div
                    className={`${styles.accidentInfoSlectBox_AccodianBtn} ${
                      !isButtonDisabled ? styles.disabled : ""
                    }`}
                    onClick={toggleSubAccordion}
                  >
                    {selectedValues.subClmTpCd ? (
                      <p>{selectedValues.subClmTpCd}</p>
                    ) : (
                      <p>선택해주세요</p>
                    )}
                    <img
                      src={commonDownArrow}
                      alt="downArrow"
                      className={subAccordionOpen ? styles.rotated : ""}
                    />
                  </div>
                  {subAccordionOpen && (
                    <div
                      className={`${styles.accidentInfoSlectBox} ${
                        !isButtonDisabled ? styles.disabled : ""
                      }`}
                    >
                      <div
                        className={`${
                          styles.accidentInfoSlectBox_AccodianContents
                        } ${!isButtonDisabled ? styles.disabled : ""}`}
                      >
                        <ul>
                          {Object.keys(
                            acdCausLctgCd[selectedValues.clmTpCd]
                          ).map((key) => (
                            <li
                              key={key}
                              className={`${styles.accidentInfoSlectItem} ${
                                selectedValues.subClmTpCd ===
                                acdCausLctgCd[selectedValues.clmTpCd][key]
                                  ? styles.selected
                                  : ""
                              }`}
                              onClick={() => selectSubClmTpCd(key)}
                              style={{
                                opacity: !isButtonDisabled ? 0.5 : 1,
                                cursor: !isButtonDisabled
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            >
                              {acdCausLctgCd[selectedValues.clmTpCd][key]}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={styles.accidentInfoDate}>
            <div className={styles.accidentInfoDateSelect}>
              <p className={styles.text}>사고일자</p>
              <div className={styles.accidentInfoDateSelectContents}>
                <div
                  className={`${styles.dateInput} ${
                    !isButtonDisabled ? styles.disabled : ""
                  }`}
                >
                  <ClaimCalendar
                    onSelect={handleDateSelect}
                    disabled={!isButtonDisabled}
                    encryptedRRN={encryptedRRN}
                    ableDate={ableDate}
                    claimableContracts={claimableContracts}
                    setSelectedContractId={setSelectedContractId}
                  />
                </div>
              </div>
              <div className={styles.claimDetailWrap}>
                <div className={styles.claimDetails}>
                  <p>진단 및 청구 내용</p>
                  <div className={styles.textAreaWrap}>
                    <textarea
                      spellCheck="false"
                      className={`${styles.customTextarea} ${
                        !isButtonDisabled ? styles.disabled : ""
                      }`}
                      value={text}
                      onChange={handleChange}
                      placeholder="최대 1000자까지 입력할 수 있습니다."
                      style={{
                        opacity: !isButtonDisabled ? 0.6 : 1,
                        pointerEvents: !isButtonDisabled ? "none" : "auto",
                      }}
                    />
                    <div className={styles.textCount}>
                      <div className={styles.currentCount}>{text.length}</div>
                      <div className={styles.slash}>/</div>
                      <div className={styles.quotientCount}>{maxLength}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.contentForm}>
                  <div className={styles.inputWrap}>
                    <label className={styles.styledLabel}>
                      휴대폰 번호(처리과정 안내문자 수신)
                    </label>
                    <div
                      className={`${styles.custominput} ${
                        !isButtonDisabled ? styles.disabled : ""
                      }`}
                    >
                      <CustomInput
                        placeholder="'-'빼고 입력해주세요."
                        type="tel"
                        maxLength={13}
                        value={phoneNumber}
                        onChange={(value) => {
                          handlePhoneNumberChange(value);
                        }}
                        onClear={() => {
                          setPhoneNumber("");
                          setError({ ...error, phoneNumber: "" });
                          setInputTouched({
                            ...inputTouched,
                            phoneNumber: false,
                          });
                        }}
                        error={shouldShowError("phoneNumber")}
                      />
                      {shouldShowError("phoneNumber") && (
                        <p style={{ color: "#E86565" }}>{error.phoneNumber}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccidentInfoSection;
