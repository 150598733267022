import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "../../css/calculationProcessCSS/indemnity.module.css";
import { selectData, setPriceData, setTotalPrice } from "../../redux/store";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ErrorModal from "../../components/modal/ErrorModal";
import Loading from "../../components/modal/Loading";
import Button from "../../components/common/Button";
import { PLAN_DETAILS, PLAN_TYPES } from "../../api/ConfirmPlanData";
import { formatPriceWithComma } from "../../utils/formatPrice";
import IndemnityAgree from "../../components/modal/IndemnityAgree";

const DepartedPlan = ({ priceData, onClickPlan, isSelected, isAgreed }) => (
  <div
    className={`${styles.wrap} ${styles.departed} ${
      isSelected && isAgreed ? styles.selected : ""
    }`}
    onClick={onClickPlan}
  >
    <div className={styles.ContentsFlexRow}>
      <div className={styles.ContentsImgWrap}>
        <img
          src="/images/airport.png"
          alt="logo"
          className={styles.ContentsImg}
        />
      </div>
      <div className={styles.platformInfoWrap}>
        <span className={styles.platformName}>{PLAN_TYPES.DEPARTED}</span>
        <div className={styles.platformPrice}>
          {formatPriceWithComma(priceData.DEPARTED.ttPrem)}원
        </div>
      </div>
    </div>
    <div className={styles.checkAnnounce}>
      <div className={styles.checkAnnounceWrap}>
        <p>가입 전 아래사항을 확인해주세요!</p>
      </div>
    </div>
    <ul className={styles.platformDetailWrap_depart}>
      <li>
        비행기 <span>출발시간 24시간</span> 이내라면, 가입 OK
      </li>
      <li>
        사망담보 3억원, 골절진단비(20만원), 상해/질병입원일당(4일이상) 3만원
      </li>
      <li>익스트림담보 1천만원 담보</li>
      <li className={styles.notice}>
        <p>*</p>
        <p>
          단, 휴대품손해, 여권재발급, 항공기수하물지연비용, 해외국내의료실비는
          담보되지 않음
        </p>
      </li>
    </ul>
  </div>
);

const RegularPlans = ({
  priceData,
  selectedData,
  handleItemClick,
  state,
  setState,
  showLite,
}) => (
  <div className={styles.plansContainer}>
    <div className={styles.regularPlansWrapper}>
      {Object.entries(PLAN_TYPES)
        .filter(([key]) => key !== "DEPARTED" && (showLite || key !== "LITE"))
        .map(([key, name]) => (
          <div
            key={key}
            className={`${styles.wrap} ${
              selectedData?.name === name ? styles.selected : ""
            }`}
            onClick={() =>
              handleItemClick({ name, price: priceData?.[key]?.ttPrem })
            }
          >
            <div className={styles.ContentsFlexRow}>
              <div className={styles.ContentsImgWrap}>
                <img
                  src={
                    name === PLAN_TYPES.RECOMMEND
                      ? "/images/Rec_icon.png"
                      : name === PLAN_TYPES.PREMIUM
                      ? "/images/Lux_icon.png"
                      : name === PLAN_TYPES.BASIC
                      ? "/images/Benefit_icon.png"
                      : "/images/Ultra_icon.png"
                  }
                  alt={`${name} icon`}
                  className={styles.ContentsImg}
                />
              </div>
              <div className={styles.platformInfoWrap}>
                <span className={styles.platformName}>{name}</span>
                <div className={styles.platformPrice}>
                  {name === PLAN_TYPES.RECOMMEND && !state.priceActivated
                    ? "???"
                    : `${formatPriceWithComma(priceData?.[key]?.ttPrem)}원`}
                </div>
              </div>
            </div>
            <div className={styles.platformDetailWrap}>
              {PLAN_DETAILS[name].description.map((text, i) => (
                <p key={i}>{text}</p>
              ))}
            </div>
            {PLAN_DETAILS[name].showRecommendButton && (
              <div
                className={styles.recommandModalBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  setState((prev) => ({ ...prev, showConfirmModal: true }));
                }}
              >
                <p className={styles.recommandModaTitle}>
                  클릭 한번으로 최적화된 플랜을 받아보세요
                </p>
              </div>
            )}
          </div>
        ))}
    </div>
  </div>
);

const Indemnity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showIndemnityAgree, setShowIndemnityAgree] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showLite, setShowLite] = useState(() => {
    return sessionStorage.getItem("showLite") === "true";
  });

  const {
    hasDeparted,
    selectedData,
    startDate,
    endDate,
    gender,
    dateOfBirth,
    companions,
    recommendedCountry,
    selectedCountryData,
    priceData,
  } = useSelector((state) => ({
    hasDeparted: state.hasDeparted.isDeparted,
    selectedData: state.plan.selectedData,
    startDate: state.calendar.selectedStartDate,
    endDate: state.calendar.selectedEndDate,
    gender: state.user.gender,
    dateOfBirth: state.user.dateOfBirth,
    companions: state.companions,
    recommendedCountry: state.country.recommendedCountryData,
    selectedCountryData: state.country.selectedCountryData,
    priceData: state.priceData.priceData,
  }));

  const [state, setState] = useState(() => ({
    showConfirmModal: false,
    isLoading: false,
    priceActivated: sessionStorage.getItem("priceActivated") === "true",
    errorMessage: "",
  }));

  // Indemnity.js
  useEffect(() => {
    if (showLite) {
      window.scrollTo({
        top: document.documentElement.scrollHeight + window.innerHeight * 0.2,
        behavior: "smooth",
      });
    }
  }, [showLite]);

  const handleShowLite = useCallback(() => {
    setShowLite(true);
    sessionStorage.setItem("showLite", "true");

    if (priceData?.LITE) {
      dispatch(
        selectData({
          name: PLAN_TYPES.LITE,
          price: priceData.LITE.ttPrem,
        })
      );
      dispatch(setTotalPrice(priceData.LITE.ttPrem));
    }
  }, [dispatch, priceData]);

  const csvData = useMemo(() => {
    try {
      const dataParam = searchParams.get("data");
      return dataParam ? JSON.parse(decodeURIComponent(dataParam)) : null;
    } catch (error) {
      console.error("CSV 데이터 파싱 오류:", error);
      return null;
    }
  }, [searchParams]);

  const { totalPersonnel, natlCds } = useMemo(
    () => ({
      totalPersonnel: csvData ? csvData.inspeCnt : 1 + companions.length,
      natlCds:
        csvData?.natlCd ||
        selectedCountryData?.cityNatlCd ||
        recommendedCountry?.cityNatlCd,
    }),
    [csvData, companions, selectedCountryData, recommendedCountry]
  );

  const createRequestData = useCallback(() => {
    if (!csvData && (!startDate || !endDate || !natlCds || !totalPersonnel)) {
      return null;
    }

    return csvData
      ? {
          ...csvData,
          hasDeparted,
        }
      : {
          hasDeparted,
          insBgnDt: startDate,
          insEdDt: endDate,
          natlCd: natlCds,
          inspeCnt: totalPersonnel.toString(),
          inspeInfos: [
            { inspeNm: "계약자", inspeBdt: dateOfBirth, gndrCd: gender },
            ...companions.map((calc, i) => ({
              inspeNm: `동반${i + 1}`,
              inspeBdt: calc.dateOfBirth,
              gndrCd: calc.gender,
            })),
          ],
        };
  }, [
    csvData,
    startDate,
    endDate,
    natlCds,
    totalPersonnel,
    dateOfBirth,
    gender,
    companions,
    hasDeparted,
  ]);

  const handleDepartedPlanClick = useCallback(() => {
    if (hasDeparted) {
      setShowIndemnityAgree(true);
      if (isAgreed) {
        const departedPlan = {
          name: PLAN_TYPES.DEPARTED,
          price: priceData.DEPARTED.ttPrem,
        };
        dispatch(selectData(departedPlan));
      }
    }
  }, [hasDeparted, priceData?.DEPARTED, dispatch, isAgreed]);

  const handleItemClick = useCallback(
    (platform) => {
      if (!hasDeparted) {
        dispatch(selectData(platform));
        if (platform.name === PLAN_TYPES.RECOMMEND) {
          setState((prev) => ({ ...prev, showConfirmModal: true }));
        }
      }
    },
    [dispatch, hasDeparted]
  );

  const handleConfirm = useCallback(() => {
    if (!state.priceActivated && !hasDeparted) {
      setState((prev) => ({
        ...prev,
        errorMessage: "추천플랜 확인 후 이용해주세요.",
      }));
      return;
    }
    if (!selectedData) {
      setState((prev) => ({ ...prev, errorMessage: "플랜을 선택해주세요." }));
      return;
    }

    if (hasDeparted && !isAgreed) {
      setState((prev) => ({
        ...prev,
        errorMessage: "이용약관에 동의해주세요.",
      }));
      return;
    }

    if (
      !hasDeparted &&
      !state.priceActivated &&
      selectedData.name === PLAN_TYPES.RECOMMEND
    ) {
      setState((prev) => ({
        ...prev,
        errorMessage: "추천 플랜 가격을 활성화 후 이용해주세요.",
      }));
      return;
    }
    navigate("/trip/confirm");
  }, [selectedData, hasDeparted, state.priceActivated, navigate, isAgreed]);

  useEffect(() => {
    if (hasDeparted && priceData?.DEPARTED && isAgreed) {
      const departedPlan = {
        name: PLAN_TYPES.DEPARTED,
        price: priceData.DEPARTED.ttPrem,
      };
      dispatch(selectData(departedPlan));
      dispatch(setTotalPrice(departedPlan.price));
      sessionStorage.setItem("selectedPlan", PLAN_TYPES.DEPARTED);
    }
  }, [hasDeparted, priceData?.DEPARTED, dispatch, isAgreed]);

  useEffect(() => {
    sessionStorage.setItem("indemnityState", JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = createRequestData();
      if (!requestData) return;

      setState((prev) => ({ ...prev, isLoading: true }));

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/trip-api/v2/trip/price`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
          }
        );

        if (!response.ok) {
          throw new Error(`알 수 없는 오류입니다.: ${response.status}`);
        }

        const result = await response.json();
        dispatch(setPriceData(result));
      } catch (error) {
        setState((prev) => ({
          ...prev,
          errorMessage: "데이터를 불러오는 중 오류가 발생했습니다.",
        }));
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }));
      }
    };

    fetchData();
  }, [createRequestData, dispatch]);

  if (state.isLoading) return <Loading />;

  return (
    <div className={styles.indemnityContents}>
      <div className={styles.indemnityWrap}>
        <div className={styles.title}>
          <div className={styles.imageWrap}>
            <img
              src="/images/meritzLogo.png"
              alt="logo"
              className={styles.ContentsImg}
            />
          </div>
          <p>
            인슈어트러스트는
            <br /> 메리츠화재와 함께해요
          </p>
        </div>
        {hasDeparted ? (
          priceData?.DEPARTED && (
            <DepartedPlan
              priceData={priceData}
              onClickPlan={handleDepartedPlanClick}
              isSelected={selectedData?.name === PLAN_TYPES.DEPARTED}
              isAgreed={isAgreed}
            />
          )
        ) : (
          <RegularPlans
            priceData={priceData}
            selectedData={selectedData}
            handleItemClick={handleItemClick}
            state={state}
            setState={setState}
            showLite={showLite}
          />
        )}
        <Button
          onClick={handleConfirm}
          disabled={!selectedData || (hasDeparted && !isAgreed)}
          buttonText="확인하기"
          showLiteButton={!hasDeparted}
          handleShowLite={handleShowLite}
        />
        {hasDeparted && (
          <IndemnityAgree
            isOpen={showIndemnityAgree}
            onClose={() => setShowIndemnityAgree(false)}
            onAgree={(agreed) => setIsAgreed(agreed)}
          />
        )}
        <ConfirmModal
          isOpen={state.showConfirmModal}
          onClose={() => {
            setState((prev) => ({
              ...prev,
              showConfirmModal: false,
              priceActivated: true,
            }));
            sessionStorage.setItem("priceActivated", "true");
          }}
        />
        {state.errorMessage && (
          <ErrorModal
            message={state.errorMessage}
            onClose={() => setState((prev) => ({ ...prev, errorMessage: "" }))}
          />
        )}
      </div>
    </div>
  );
};

export default Indemnity;
