const AccordionData = [
  {
    title: "공통 서류",
    subtitle: "필요서류 안내",
    commonDocs: {
      docs: [
        "보험금 청구서",
        "개인(신용) 정보처리동의서",
        "보험증권",
        "여권사본 (본인확인, 입출국 확인)",
        "본인 수령 시 통장사본",
      ],
    },
  },
  {
    title: "통원/입원 치료",
    docs: [
      "의료기관 예약, 방문치료: 병원 진단서, 치료영수증 등을 구비",
      "약국에서 약을 구입하여 복용한 경우: 영수증 구비",
    ],
  },
  {
    title: "상해사망",
    docs: ["피보험자의 호적등본", "위임장 (필요시)"],
  },
  {
    title: "배상책임",
    docs: [
      "대인: 제 3자의 진단서 및 치료비 영수증",
      "대물: 손해증빙서류 및 손상물 수리 견적서 ",
    ],
  },
  {
    title: "휴대품 손해",
    docs: [
      "사고증명서 (도난 증명서, 현지경찰확인서 등)",
      "손해명세서 (손상물 수리 견적서, 파손된 휴대품의 사진 등)",
      "피해품의 구입가격, 구입처 등이 적힌 서류",
    ],
  },
];

export default AccordionData;
