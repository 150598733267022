import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import CalculationProcessRouter from "../pages/calculationProcess/CalculationProcessRouter";
import SignUpProcessRouter from "../pages/signUpProcess/SignUpProcessRouter";
import ClaimProcessRouter from "../../src/pages/claimPages/ClaimProcessRouter";
import IndividualInfo from "../components/common/IndividualInfo";
import ServiceAnnounce from "../components/common/ServiceAnnounce";
import useAnalytics from "../hooks/useAnalytics";

function RouteTracker() {
  const location = useLocation();
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location, logPageView]);

  return null;
}

function Router() {
  const [reOrderUser, setReOrderUser] = useState("");

  return (
    <BrowserRouter>
      <RouteTracker />
      <Routes>
        {/* 청구 */}
        <Route path="/*" element={<ClaimProcessRouter />} />

        {/* 가입전 여행 정보 입력 */}
        <Route
          path="/trip/*"
          element={<CalculationProcessRouter setReOrderUser={setReOrderUser} />}
        />

        {/* 유저정보 입력 가입페이지 부터 결제 */}
        <Route
          path="/signup/*"
          element={<SignUpProcessRouter reOrderUser={reOrderUser} />}
        />
        <Route path="/serviceInfo" element={<ServiceAnnounce />} />
        <Route path="/individualInfo" element={<IndividualInfo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
