import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux"; // useSelector 추가
import styles from "../../css/signUpProcessCSS/Member.module.css";
import { InputDeleteSVG } from "./SvgIcon/RestFinishSVG";

const CustomInput = ({
  width,
  placeholder,
  type,
  maxLength,
  value,
  onChange,
  error,
  onClear,
  isErrorFocused,
  readOnly,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const hasDeparted = useSelector((state) => state.hasDeparted.isDeparted); // hasDeparted 상태 추가

  useEffect(() => {
    if (isErrorFocused && error && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isErrorFocused, error]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (onChange) {
      onChange(inputValue);
    }
  };

  const valueDeleteClick = () => {
    if (onClear) {
      onClear();
    }
  };

  const containerStyle = {
    width: width ? width : "100% ",
    background: "#f3f4f6",
    color: "#B8B9BC",
    border: isFocused
      ? `2px solid ${hasDeparted ? "#0e98f6" : "#386937"}`
      : error
      ? "2px solid #E86565"
      : "2px solid transparent",
  };

  const inputStyle = {
    width: "100%",
    outline: "none",
  };

  const handleFocus = () => {
    if (readOnly) {
      inputRef.current.blur();
    } else {
      setIsFocused(true);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={`${styles.styledInputBox} ${readOnly ? styles.readOnly : ""}`}
      style={containerStyle}
    >
      <input
        ref={inputRef}
        className={styles.styledInput}
        type={type}
        value={value || ""}
        onChange={handleInputChange}
        maxLength={maxLength}
        placeholder={placeholder}
        style={inputStyle}
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly={readOnly}
        onClick={onclick}
      />
      {onClear && placeholder !== "앞자리" && value && (
        <InputDeleteSVG onClick={valueDeleteClick} />
      )}
    </div>
  );
};

export default CustomInput;
