import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/Claim/claimFooter.module.css";
import DownChk from "../../assets/DownChk.svg";

function ClaimFooter() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <div className={styles.footers}>
      <div className={styles.footersWrap}>
        <div className={styles.footerHeader}>
          <div className={styles.companyInfo}>
            <p>{process.env.REACT_APP_COMPANY_NAME}</p>
            <p>{process.env.REACT_APP_TEL_NO}</p>
          </div>
          <div className={styles.companyInfo2}>
            <span>9:00~18:00 (점심시간 12:00-13:00)</span>
            <span>DAY OFF (토/일/공휴일)</span>
          </div>
        </div>
        <div className={styles.boundaryWrap}>
          <div className={styles.boundary}></div>
        </div>
        <div className={styles.footerListwrap}>
          <div className={styles.footerList}>
            <ul>
              <li
                onClick={() => handleNavigation("/emergencySupport")}
                className="cursor-pointer"
              >
                24시간 우리말의료상담
              </li>
              <li
                onClick={() => handleNavigation("/individualInfo")}
                className="cursor-pointer"
              >
                개인정보처리방침
              </li>
              <li
                onClick={() => handleNavigation("/serviceInfo")}
                className="cursor-pointer"
              >
                서비스 이용약관
              </li>
            </ul>

            <img
              src={DownChk}
              alt="DownChk"
              className={`${styles.toggleIcon} ${isOpen ? styles.rotated : ""}`}
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
          <div
            className={`${styles.footerBody} ${isOpen ? styles.expanded : ""}`}
          >
            <div className={styles.hiddenContent}>
              <p>(주)리트러스트 | 대표 장우석</p>
              <p>사업자 등록번호 370-88-02749</p>
              <p>통신판매업신고번호 제 2024-부산남구-0464호</p>
              <p>대리점등록번호 2024040026호</p>
              <p>주소 부산시 남구 문현 금융로 40 BIFC 8층 2호</p>
            </div>
          </div>
          <div className={styles.copyright}>
            <p>Copyright© Retrust. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimFooter;
