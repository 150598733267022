import React, { useEffect, useState } from "react";
import ClaimHeader from "../../components/claimComponents/ClaimHeader";
import ClaimInfoSection from "../../components/claimComponents/ClaimInfoSection";
import AccidentInfoSection from "../../components/claimComponents/AccidentInfoSection";
import AccountInfoSection from "../../components/claimComponents/AccountInfoSection";
import SecretNumberSection from "../../components/claimComponents/SecretNumberSection";
import styles from "../../css/Claim/claimMembersInfo.module.css";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modal/ErrorModal";
import ClaimButton from "../../components/common/ClaimButton";

function ClaimMembersInfo() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [text, setText] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [accidentDate, setAccidentDate] = useState(null);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBankCd, setSelectedBankCd] = useState("");
  const [accidentDateKey, setAccidentDateKey] = useState("");
  const [claimTypeKey, setClaimTypeKey] = useState("");
  const [encryptedAccountNumber, setEncryptedAccountNumber] = useState(null);
  const [encryptedRRN, setEncryptedRRN] = useState("");
  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  const [encryptedResidentNumber, setEncryptedResidentNumber] = useState(null);
  const [relationCd, setRelationCd] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  //레지스트 api를 호출하기 위한 id값 props로 받기
  const [selectedContractId, setSelectedContractId] = useState(null);

  const [error, setError] = useState({
    phoneNumber: "",
    accountHolderName: "",
    accountNumber: "",
  });
  const [selectedValues, setSelectedValues] = useState({
    clmTpCd: null,
    subClmTpCd: null,
  });
  const claim_types = selectedValues.clmTpCd;
  const accident_cause_cds = selectedValues.subClmTpCd;
  const selected_clmTpCd_Name = selectedValues.clmTpCd;

  const isSpecialClaimType = false;
  // selected_clmTpCd_Name === "배상" || selected_clmTpCd_Name === "재물";

  // useEffect(() => {
  //   if (selected_clmTpCd_Name === "재물" || selected_clmTpCd_Name === "배상") {
  //     setIsModal(true); // Show error modal
  //   } else {
  //     setIsModal(false); // Hide error modal
  //   }
  // }, [selected_clmTpCd_Name]);

  useEffect(() => {
    setIsFormValid(validateFields());
  }, [
    phoneNumber,
    accountHolderName,
    accountNumber,
    accidentDate,
    encryptedAccountNumber,
    encryptedRRN,
    encryptedResidentNumber,
    selectedBank,
    selectedBankCd,
    accidentDateKey,
    claimTypeKey,
    selectedName,
    selected_clmTpCd_Name,
  ]);

  const navigate = useNavigate();
  const handleEncryptedAccountNumber = (encryptedData) => {
    setEncryptedAccountNumber(encryptedData);
  };
  const EncryptedAccountSecretNum = (encryptedData) => {
    setEncryptedResidentNumber(encryptedData);
  };
  const handleAccountNumberChange = (value) => {
    value = value.replace(/\D/g, "");
    setAccountNumber(value);
  };
  const handleDateSelect = (date) => {
    setAccidentDate(date);
  };
  const handleOptionClick = (key) => {
    setIsActive(key);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    if (value.length <= 1000) {
      setText(value);
    }
  };
  const isValidKoreanName = (name) => {
    const koreanRegex = /^[가-힣]+$/;
    return koreanRegex.test(name) && name.length >= 2;
  };

  const validateFields = () => {
    if (isSpecialClaimType) return true;

    const phoneRegex = /^01[0-9]-[0-9]{3,4}-[0-9]{4}$/;

    let phoneNumberError = phoneRegex.test(phoneNumber)
      ? ""
      : "유효하지 않은 전화번호입니다.";

    let accountHolderNameError = isValidKoreanName(accountHolderName)
      ? ""
      : "유효하지 않은 이름입니다.";

    setError({
      phoneNumber: phoneNumberError,
      accountHolderName: accountHolderNameError,
    });

    return !(phoneNumberError || accountHolderNameError);
  };

  const callClaimApi = async (contractId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      encryptedData: encryptedRRN.encryptedData,
      encryptedKey: encryptedRRN.encryptedKey,
      iv: encryptedRRN.iv,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/trip-api/auth/regist/${contractId}`,
        requestOptions
      );
      if (!response.ok) {
        setIsModal(true);
        return false;
      }
      const result = await response.json();
      console.log("메리츠 멤버십 성공:", result);
      return true;
    } catch (error) {
      console.log(error, "메리츠 멤버십 실패");
      setIsModal(true);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (isFormValid || isSpecialClaimType) {
      try {
        if (selectedContractId) {
          const apiResult = await callClaimApi(selectedContractId);
          if (!apiResult) {
            return; // API 호출 실패시 종료
          }
        }

        const claimType = isActive;

        let formattedAccidentDate = null;
        if (accidentDate instanceof Date && !isNaN(accidentDate)) {
          const year = accidentDate.getFullYear();
          const month = (accidentDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const day = accidentDate.getDate().toString().padStart(2, "0");
          formattedAccidentDate = `${year}${month}${day}`;
        }

        const phoneParts = phoneNumber.split("-");
        const arCcoCd1 = phoneParts[0] || "";
        const telofNo1 = phoneParts[1] || "";
        const telSno1 = phoneParts[2] || "";

        const collectionData = {
          text,
          encryptedData: encryptedRRN?.encryptedData || null,
          encryptedKey: encryptedRRN?.encryptedKey || null,
          iv: encryptedRRN?.iv || null,
          accountEncryptedData: encryptedAccountNumber?.encryptedData || null,
          accountIv: encryptedAccountNumber?.iv || null,
          accountEncryptedKey: encryptedAccountNumber?.encryptedKey || null,
          accountHolderName,
          depositorEncryptedKey: encryptedResidentNumber?.encryptedKey || null,
          depositorIv: encryptedResidentNumber?.iv || null,
          depositorEncryptedData:
            encryptedResidentNumber?.encryptedData || null,
          claimType,
          claim_types,
          accident_cause_cds,
          accidentDate: formattedAccidentDate,
          selectedBank,
          selectedBankCd,
          relationCd,
          arCcoCd1,
          telofNo1,
          telSno1,
          accidentDateKey,
          claimTypeKey,
          selectedName,
        };

        navigate("/claimAgree", { state: collectionData });
        console.log("제출 데이터:", collectionData);
      } catch (error) {
        console.error("오류 발생:", error);
        setIsModal(true);
      }
    }
  };

  return (
    <>
      <ClaimHeader titleText="청구하기" />
      <div className={styles.sectionWrap}>
        <ClaimInfoSection
          isActive={isActive}
          handleOptionClick={handleOptionClick}
          setEncryptedRRN={setEncryptedRRN}
          setSelectedClaimDate={setSelectedClaimDate}
          setSelectedName={setSelectedName}
          isButtonDisabled={!isSpecialClaimType}
        />
        <div className={styles.boundaryWrap}>
          <div className={styles.boundary}></div>
        </div>
        <AccidentInfoSection
          text={text}
          handleChange={handleChange}
          handleDateSelect={handleDateSelect}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          error={error}
          setError={setError}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          setAccidentDate={setAccidentDate}
          accidentDate={accidentDate}
          setClaimTypeKey={setClaimTypeKey}
          setAccidentDateKey={setAccidentDateKey}
          selectedClaimDate={selectedClaimDate}
          isButtonDisabled={!isSpecialClaimType}
          encryptedRRN={encryptedRRN}
          setSelectedContractId={setSelectedContractId}
        />
        <div className={styles.boundaryWrap}>
          <div className={styles.boundary}></div>
        </div>
        <AccountInfoSection
          error={error}
          setError={setError}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          accountHolderName={accountHolderName}
          setAccountHolderName={setAccountHolderName}
          handleAccountNumberChange={handleAccountNumberChange}
          isValidKoreanName={isValidKoreanName}
          selectedBank={selectedBank}
          setSelectedBank={setSelectedBank}
          selectedBankCd={selectedBankCd}
          setSelectedBankCd={setSelectedBankCd}
          handleEncryptedAccountNumber={handleEncryptedAccountNumber}
          EncryptedAccountSecretNum={EncryptedAccountSecretNum}
          selectedClaimDate={selectedClaimDate}
          setRelationCd={setRelationCd}
          isButtonDisabled={!isSpecialClaimType}
        />
        <SecretNumberSection />
      </div>
      <div className={styles.buttonWrap}>
        <div className={styles.buttonBoundary}></div>
        <div className={styles.buttonBackground}></div>
        <ClaimButton
          buttonText="다음"
          onClick={handleSubmit}
          disabled={!isFormValid && !isSpecialClaimType}
        />
      </div>
      {isModal && (
        <ErrorModal
          message="재물 또는 배상 유형은 신청서를 작성 후 업로드 부탁드립니다."
          onClose={() => setIsModal(false)}
        />
      )}
    </>
  );
}

export default ClaimMembersInfo;
