import React, { useState, useEffect } from "react";
import Loading from "../modal/Loading";
import ErrorModal from "../modal/ErrorModal";

const WalletButton = ({ insuranceId }) => {
  const [isIOS, setIsIOS] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [subMsg, setSubMsg] = useState("");

  useEffect(() => {
    const checkDevice = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    };

    checkDevice();
    window.addEventListener("resize", checkDevice);
    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  async function generatePass() {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_APPLE_WALLET_PARTNER_URL}/create-pass/${insuranceId}`,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error("증서 생성에 실패했습니다.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      window.location.href = url;

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      console.error("증서 생성 오류:", error);
      setShowError(true);
      setErrorMsg("증서 생성 오류");
      setSubMsg(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleWalletAction = async () => {
    if (isIOS) {
      await generatePass();
    } else {
      try {
        setLoading(true);
        const response = await fetch(
          // `/wallet-api/generate-generic/${insuranceId}`
          `/wallet-api/generate-cdata/${insuranceId}`
        );
        const data = await response.json();

        if (data.cdata) {
          // const atwLink = `${process.env.REACT_APP_SAMSUNG_WALLET_URL}/3ho55m9j2q000#Clip?cdata=${data.cdata}`;
          const atwLink = `${process.env.REACT_APP_SAMSUNG_WALLET_URL}/3huo7fsg2i0g0#Clip?cdata=${data.cdata}`;
          // const atwLink = `${process.env.REACT_APP_SAMSUNG_WALLET_URL}/3i1o0h7aveh00#Clip?cdata=${data.cdata}`;
          window.location.href = atwLink;
        }
      } catch (error) {
        console.error("Error:", error);
        setShowError(true);
        setErrorMsg("증서 생성 오류");
        setSubMsg(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="w-full px-5">
      {isIOS ? (
        <img
          src="/images/addToWallet/add-to-apple-wallet.png"
          alt="Add to Apple Wallet"
          className="w-auto"
          onClick={handleWalletAction}
        />
      ) : (
        <img
          src="/images/addToWallet/add-to-samsung-wallet.png"
          alt="Add to Samsung Wallet"
          className="w-auto"
          onClick={handleWalletAction}
        />
      )}
      {loading && <Loading />}
      {showError && (
        <ErrorModal
          message={errorMsg}
          subMsg={subMsg}
          onClose={() => setShowError(false)}
        />
      )}
    </div>
  );
};

export default WalletButton;
