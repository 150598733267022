// Import necessary components and dependencies
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../../components/common/Header";
import Progressbar from "../../components/calculationProcessPart/Progressbar";
import Intro from "./Intro";
import Insert from "./Insert";
import TripSelect from "./TripSelect";
import Indemnity from "./Indemnity";
import Confirm from "./Confirm";
import NotFoundPage from "../NotFoundPage";
import useScrollToTop from "../../hooks/useNavigateWithScrollToTop";
import ExcelUpLoad from "../../components/calculationProcessPart/ExcelUpLoad";
import DepartedIntro from "./DepartedIntro";
import Upload from "./Upload";

function CalculationProcessRouter({ setReOrderUser, reOrderUser }) {
  const [showHeader, setShowHeader] = useState(true);
  const [showbar, setShowbar] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [faRetrustData, setFaRetrustData] = useState(null);

  useEffect(() => {
    const birthDate = searchParams.get("birthDate");
    const gender = searchParams.get("gender");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    setFaRetrustData({ birthDate, gender, startDate, endDate });
  }, []);

  useScrollToTop();

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/"
        element={
          <>
            {showHeader && <Header />}
            {showbar && <Progressbar />}
            <Intro
              setShowHeader={setShowHeader}
              setShowbar={setShowbar}
              setReOrderUser={setReOrderUser}
              reOrderUser={reOrderUser}
            />
          </>
        }
      />
      <Route
        path="/departed"
        element={
          <>
            {showHeader && <Header />}
            {showbar && <Progressbar />}
            <DepartedIntro
              setShowHeader={setShowHeader}
              setShowbar={setShowbar}
              setReOrderUser={setReOrderUser}
              reOrderUser={reOrderUser}
            />
          </>
        }
      />
      <Route
        path="/insert"
        element={
          <>
            {showHeader && <Header />}
            {showbar && <Progressbar />}
            <Insert
              faRetrustData={faRetrustData}
              setReOrderUser={setReOrderUser}
              reOrderUser={reOrderUser}
            />
          </>
        }
      />

      <Route
        path="/upload"
        element={
          <>
            {showHeader && <Header />}
            <Upload
              faRetrustData={faRetrustData}
              setReOrderUser={setReOrderUser}
              reOrderUser={reOrderUser}
            />
          </>
        }
      />
      <Route
        path="/groupEstimate"
        element={
          <>
            {showHeader && <Header />}
            <ExcelUpLoad
              faRetrustData={faRetrustData}
              setReOrderUser={setReOrderUser}
              reOrderUser={reOrderUser}
            />
          </>
        }
      />
      <Route
        path="/trip"
        element={
          <>
            {showbar && <Progressbar />}
            <TripSelect />
          </>
        }
      />
      <Route
        path="/indemnity"
        element={
          <>
            {showHeader && <Header />}
            {showbar && <Progressbar />}
            <Indemnity />
          </>
        }
      />
      <Route
        path="/confirm"
        element={
          <>
            {showHeader && <Header />}
            {showbar && <Progressbar />}
            <Confirm />
          </>
        }
      />
    </Routes>
  );
}
export default CalculationProcessRouter;
