import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../../css/Comnons/errorModal.module.css";
import errorModal from "../../assets/errorModal.svg";
import commonX from "../../assets/commonX.svg";
import modalOverlay from "../../css/Comnons/modalLayOut.module.css";

function ErrorModal({ message, onClose, subMsg }) {
  const location = useLocation();
  const hasDeparted = useSelector((state) => state.hasDeparted.isDeparted);

  // 가입 프로세스 체크
  const isSignupProcess =
    location.pathname.startsWith("/signup") ||
    location.pathname.startsWith("/trip");

  const getModalImage = () => {
    // 청구 프로세스거나 hasDeparted가 false인 경우
    if (!isSignupProcess || !hasDeparted) {
      return <img src={errorModal} alt="공통 에러 모달" />;
    }
    // 가입 프로세스이고 hasDeparted가 true인 경우
    return (
      <img
        src="/images/dp_errorModal.png"
        alt="출국 후 에러모달"
      />
    );
  };

  const getButtonColor = () => {
    // 가입 프로세스인 경우
    if (isSignupProcess) {
      return hasDeparted ? "#169AF5" : "#386937";
    }
    // 청구 프로세스인 경우
    return "#386937";
  };

  return (
    <div className={modalOverlay.modalOverlay}>
      <div className={modalOverlay.modal_center}>
        <div className={styles.contents}>
          <div className={styles.commonX}>
            <img src={commonX} alt="닫기" onClick={onClose} />
          </div>
          <div className={styles.imageWrap}>{getModalImage()}</div>
          <h3>{message}</h3>
          <p>{subMsg}</p>
          <button
            type="button"
            className={styles.confirmButton}
            onClick={onClose}
            style={{ backgroundColor: getButtonColor() }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
