// Button.jsx
import React from "react";
import styles from "../../css/Comnons/Button.module.css";

function ClaimButton({
  buttonText = "확인하기",
  onClick,
  disabled,
  isFinishPath,
}) {

  const handleClick = (e) => {
    e.preventDefault();
    if (onClick) onClick();
  };

  return (
    <div className={styles.buttonContainer}>
      <div className={`${styles.buttonWrap}`}>
        <div
          className={isFinishPath ? styles.finishButtonBOX : styles.buttonBox}
        >
          <button
            onClick={handleClick}
            className={styles.buttonStyle}
            disabled={disabled}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClaimButton;
