import React from "react";
import styles from "../../css/Comnons/SucceedModal.module.css";
import claimSuccessChk from "../../assets/claimSuccessChk.svg";
import commonX from "../../assets/commonX.svg";
import { useNavigate } from "react-router-dom";

function SucceedModal({ onClose, message, arrayResultData }) {
  const navigate = useNavigate();

  const handleConfirmClick = () => {
    navigate("/");
  };

  const handleContinueClick = () => {
    navigate("/claimMembersIntro");
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalWrap}>
          <div className={styles.commonX}>
            <img src={commonX} alt="닫기" onClick={onClose} />
          </div>
          <img src={claimSuccessChk} alt="claimSuccessChk" />
          <div className={styles.modalTitle}>
            <p>{message}</p>
            <p>가 완료되었습니다.</p>
          </div>
          <div className={styles.TextContent}>
            <p>접수번호 :</p>
            <span>
              {arrayResultData.accident_year}-{arrayResultData.accident_seq}
            </span>
          </div>
          <div className={styles.buttonWrap}>
            <button onClick={handleConfirmClick}>확인</button>
            <button onClick={handleContinueClick}>청구(계속)</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SucceedModal;
