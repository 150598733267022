import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../css/calculationProcessCSS/Confirm.module.css";
import confirmCheck from "../../assets/confirmCheck.svg";
import { getGenderString } from "../../components/calculationProcessPart/Gender";
import ModifyModal from "../modal/Modifymodal";
import {
  formatKoreanDate,
  formatDateString,
  formatPersonalId,
} from "../../utils/birthDate";
import { formatStringPrice } from "../../utils/formatPrice";
import departedConfirmChk from "../../assets/departedConfirmChk.svg";
import { PLAN_KEYS } from "../../api/ConfirmPlanData";

function ConfirmAndGuarantee() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userAge, setUserAge] = useState("");
  const [userPremium, setUserPremium] = useState(null);
  const [companionPremiums, setCompanionPremiums] = useState([]);
  const [isDataVisible, setIsDataVisible] = useState(false);

  // Redux Selectors
  const hasDeparted = useSelector((state) => state.hasDeparted.isDeparted);
  const selectedData = useSelector((state) => state.plan.selectedData);
  const priceData = useSelector((state) => state.priceData.priceData);
  const companions = useSelector((state) => state.companions);
  const selectedStartDate = useSelector(
    (state) => state.calendar.selectedStartDate
  );
  const selectedEndDate = useSelector(
    (state) => state.calendar.selectedEndDate
  );
  const totalPrice = useSelector((state) => state.totalPrice.totalPrice);
  const selectedPlanName = useSelector((state) => state.plan.selectedPlanName);
  const userDateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const userGender = useSelector((state) => state.user.gender);
  const membersInfo = useSelector((state) => state.members.members);
  const companionDataInfo = membersInfo.companionData || [];
  const companionName = companionDataInfo.map((e) => e.name);

  useEffect(() => {
    if (!priceData) return;

    if (hasDeparted && priceData?.opapiGnrCoprCtrInspeInfCbcVo) {
      const departedData = priceData?.opapiGnrCoprCtrInspeInfCbcVo;
      setUserPremium(departedData[0].ppsPrem);
      setCompanionPremiums(
        departedData.slice(1).map((companion) => ({
          premium: companion.ppsPrem,
          dateOfBirth: companion.inspeBdt,
          gender: companion.gndrCd,
        }))
      );
      return;
    }

    if (!selectedData.name) return;

    const planKey = PLAN_KEYS[selectedData.name];
    if (!planKey) return;

    const planData = priceData[planKey]?.opapiGnrCoprCtrInspeInfCbcVo;
    if (!planData) return;

    const user = planData.find((item) => item.cusNm === "계약자");
    const companionData = planData.filter((item) => item.cusNm !== "계약자");

    setUserPremium(user?.ppsPrem || null);
    setCompanionPremiums(
      companionData.map((companion, index) => ({
        premium: companion.ppsPrem,
        dateOfBirth: companions[index]?.dateOfBirth || null,
        gender: companions[index]?.gender || companion.gndrCd,
      }))
    );
  }, [hasDeparted, selectedData.name, priceData, companions]);

  useEffect(() => {
    if (userDateOfBirth) {
      const { year } = formatDateString(userDateOfBirth);
      const currentYear = new Date().getFullYear();
      setUserAge(currentYear - parseInt(year));
    }
  }, [userDateOfBirth]);

  const handleModifyClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDataVisibility = () => {
    setIsDataVisible(!isDataVisible);
  };
  return (
    <div className={styles.confirmContents}>
      <div className={styles.confirmWrap}>
        <h3>
          선택하신{" "}
          <span
            className={hasDeparted ? styles.hasDeparteds : styles.highlight}
          >
            보장내용
          </span>
          과{" "}
          <span
            className={hasDeparted ? styles.hasDeparteds : styles.highlight}
          >
            보험료
          </span>
          를 <br />
          안내해드립니다.
        </h3>
        <section className={hasDeparted ? styles.on : styles.section}>
          <div className={styles.sectionWrap}>
            <div className={styles.sectionWrap_title}>
              <div className={styles.ContentsImgWrap}>
                <img
                  src={
                    selectedData.name === "추천 플랜"
                      ? "/images/Rec_icon.png"
                      : selectedData.name === "럭셔리 플랜"
                      ? "/images/Lux_icon.png"
                      : selectedData.name === "갓성비 플랜"
                      ? "/images/Benefit_icon.png"
                      : selectedData.name === "초실속 플랜"
                      ? "/images/Ultra_icon.png"
                      : "/images/airport.png"
                  }
                  alt={`${selectedPlanName} 로고`}
                  className={styles.ContentsImg}
                />
              </div>
              <p className="text-xl font-semibold">
                {hasDeparted
                  ? "출국 후 여행자 보험"
                  : selectedPlanName || selectedData.name}
              </p>
            </div>
            <div className={styles.section_DataContents}>
              <div className={styles.dataContext}>
                <p>가입내용</p>
                <span
                  onClick={handleModifyClick}
                  className={`${styles.modifyButton} ${
                    hasDeparted ? styles.hasDeparted : ""
                  }`}
                >
                  수정하기
                </span>
              </div>
              <div className={styles.section_DataContentsWrap}>
                <div className={styles.dataFlexbox}>
                  <p>출발 일정일</p>
                  <span>{formatKoreanDate(selectedStartDate)}</span>
                </div>
                <div className={styles.dataFlexbox}>
                  <p>도착 일정일</p>
                  <span>{formatKoreanDate(selectedEndDate)}</span>
                </div>
                <div className={styles.dataFlexbox}>
                  <p>가입자</p>
                  <div className={styles.dataFlexCol}>
                    <span>
                      {hasDeparted
                        ? `${getGenderString(userGender)} (만 ${userAge}세)${
                            companionPremiums.length > 0
                              ? ` 외 ${companionPremiums.length}명`
                              : ""
                          }`
                        : `${getGenderString(userGender)} (만 ${userAge}세)${
                            companions.length > 0
                              ? ` 외 ${companions.length}명`
                              : ""
                          }`}
                    </span>
                  </div>
                </div>
                <div className={styles.section_cost}>
                  <div className={styles.section_costWrap}>
                    <p className={hasDeparted ? styles.hasDeparted : ""}>
                      총 보험료
                    </p>
                    <span className={hasDeparted ? styles.hasDeparted : ""}>
                      {hasDeparted
                        ? parseInt(
                            priceData?.DEPARTED?.ttPrem || 0
                          ).toLocaleString()
                        : parseInt(
                            totalPrice?.replace(/[^0-9]/g, "") || 0
                          ).toLocaleString()}
                      원
                    </span>
                    <img
                      src={hasDeparted ? departedConfirmChk : confirmCheck}
                      className="cursor-pointer ml-2"
                      onClick={toggleDataVisibility}
                      alt="confirmCheck"
                    />
                  </div>
                  <div
                    className={`bg-white rounded-xl w-full ${
                      isDataVisible ? "" : "hidden"
                    } bg-opacity-0 py-4`}
                  >
                    <div className={styles.detailInfo}>
                      <div className={styles.TextFlex}>
                        <p>
                          {membersInfo.name}(계약자) :{" "}
                          {userPremium
                            ? `${formatStringPrice(userPremium)}원`
                            : "정보 없음"}
                        </p>
                        <p>{formatPersonalId(userDateOfBirth, userGender)}</p>
                      </div>
                      {companionPremiums.length > 0 &&
                        companions.length > 0 &&
                        companions.map((companion, index) => (
                          <div key={index} className={styles.TextFlex}>
                            <p>
                              {hasDeparted
                                ? `동반${index + 1}`
                                : companionName[index]}
                              :{" "}
                              {formatStringPrice(
                                companionPremiums[index]?.premium
                              )}
                              원
                            </p>
                            <p>
                              {formatPersonalId(
                                companion.dateOfBirth,
                                companion.gender
                              )}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModifyModal isOpen={isModalOpen} onClose={closeModal} />
        </section>
      </div>
    </div>
  );
}

export default ConfirmAndGuarantee;
