// Button.jsx
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../css/Comnons/Button.module.css";
import { useDispatch, useSelector } from "react-redux";
import LiteButton from "./LiteButton";
import { setHasDeparted } from "../../redux/store";

function Button({
  buttonText = "확인하기",
  onClick,
  disabled,
  andColor,
  isFinishPath,
  showLiteButton,
  handleShowLite,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const isIntroPath = ["/trip", "/trip/departed"].includes(location.pathname);
  const isTripPath = location.pathname === "/trip/trip";
  const isIndemnityPath = location.pathname === "/trip/indemnity";
  const hasDeparted = useSelector((state) => state.hasDeparted.isDeparted);

  // 가입 프로세스 체크 로직
  const isSignupProcess =
    location.pathname.startsWith("/signup") ||
    location.pathname.startsWith("/trip");

  useEffect(() => {
    // 청구 프로세스('/'로 시작하는 경로)에서는 hasDeparted를 false로 설정
    if (!isSignupProcess) {
      dispatch(setHasDeparted(false));
    }
  }, [location.pathname, dispatch, isSignupProcess]);

  const handleClick = (e) => {
    e.preventDefault();
    if (onClick) onClick();

    if (location.pathname === "/trip") {
      dispatch(setHasDeparted(false));
    } else if (location.pathname === "/trip/departed") {
      dispatch(setHasDeparted(true));
    }
  };

  const getButtonColor = () => {
    // 가입 프로세스인 경우에만 조건부 색상 적용
    if (isSignupProcess) {
      if (location.pathname === "/trip") return "#386937";
      if (location.pathname === "/trip/departed") return "#169AF5";

      if (disabled) return hasDeparted ? "#B8DBFB" : "#C3D2C3";
      if (andColor) return "#C3D2C3";
      if (isFinishPath) return "#1F3A1E";

      return hasDeparted ? "#169AF5" : "#386937";
    }

    // 청구 프로세스 등 다른 모든 경로에서는 #386937 반환
    return "#386937";
  };

  return (
    <div className={styles.buttonContainer}>
      <div
        className={`${styles.buttonWrap} ${isIntroPath ? styles.on : ""} ${
          isTripPath ? styles.trip : ""
        }`}
      >
        <div
          className={isFinishPath ? styles.finishButtonBOX : styles.buttonBox}
        >
          <LiteButton
            showLiteButton={showLiteButton}
            handleShowLite={handleShowLite}
            isIndemnityPath={isIndemnityPath}
            hasDeparted={hasDeparted}
          />
          <button
            onClick={handleClick}
            className={styles.buttonStyle}
            style={{ backgroundColor: getButtonColor() }}
            disabled={disabled}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}
export default Button;
