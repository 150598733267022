import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../../css/signUpProcessCSS/Finsh.module.css";
import { FinishSVG } from "../../components/common/SvgIcon/RestFinishSVG";
import ModalFinish from "../../components/modal/ModalFinish";
import FinishCheck from "../../assets/FinishCheck.svg";
import DpReceiveBtn from "../../assets/DpReceiveBtn.svg";
import QRCodeModal from "../../components/modal/QRCodeModal";
import { useSelector } from "react-redux";
import WalletButton from "../../components/common/WalletButton";

function Finish() {
  const { tid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const { natlCd, nation, city, start_date, end_date } =
    Object.fromEntries(searchParams);
  const [showModal, setShowModal] = useState(true);
  const [buttonText, setButtonText] = useState("NFT 생성중");
  const [isNFTReceived, setIsNFTReceived] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cardId, setCardId] = useState("");
  const [insuranceId, setInsuranceId] = useState("");
  const [popupWindow, setPopupWindow] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [klipRequestId, setKlipRequestId] = useState("");
  const [klipDeepLink, setKlipDeepLink] = useState(null);
  const [goKlip, setGoKlip] = useState(null);
  const [error, setError] = useState(null);
  const [showWalletButton, setShowWalletButton] = useState(false);

  const cardData = JSON.stringify({
    natlCd: natlCd,
    nation: nation,
    city: city,
    start_date: start_date,
    end_date: end_date,
  });
  const hasDeparted = useSelector((state) => state.hasDeparted.isDeparted);

  useEffect(() => {
    const fetchKlipData = async () => {
      try {
        const response = await fetch("/auth/klip", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          const { deep_link, request_id } = result.data;
          setKlipDeepLink(deep_link);
          setKlipRequestId(request_id);
          setShowModal(false);
        } else {
          throw new Error("Failed to fetch Klip data");
        }
      } catch (error) {
        console.error("Error fetching Klip data:", error);
        setError("Klip 데이터를 가져오는데 실패했습니다.");
      }
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`/escrow-api/mint/${tid}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: cardData,
        });

        const cardInfo = await response.json();
        if (cardInfo.success) {
          setCardId(cardInfo.cards[0].card_id_hex);
          setInsuranceId(cardInfo.cards[0].insurance);
          setGoKlip(cardInfo.cards[0].goKlip);
          fetchKlipData();
          setIsLoading(false);
          setButtonText("NFT 받기");
          setShowWalletButton(true);
        } else {
          console.error("Error:", cardInfo);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();

    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        )
      );
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    if (isNFTReceived) {
      navigate("/");
    }
  };

  const openKlipModal = () => {
    setShowModal(false);
    if (isMobile) {
      openPopupWindow(klipDeepLink);
    } else {
      setShowQRModal(true);
    }
  };

  const openPopupWindow = (url) => {
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }
    if (intervalId) {
      clearInterval(intervalId);
    }

    const width = window.innerWidth * 0.9;
    const height = window.innerHeight * 0.9;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      url,
      "KlipLogin",
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setPopupWindow(popup);

    const newIntervalId = setInterval(() => {
      if (popup.closed) {
        clearInterval(newIntervalId);
        setIntervalId(null);
        setPopupWindow(null);
      }
    }, 1000);

    setIntervalId(newIntervalId);

    setTimeout(() => {
      if (popup && !popup.closed) {
        popup.close();
      }
      clearInterval(newIntervalId);
      setIntervalId(null);
      setPopupWindow(null);
    }, 5 * 1000);
  };

  const handleNFTReceive = async () => {
    setError(null);
    try {
      if (isMobile) {
        openPopupWindow(klipDeepLink);
      } else {
        setShowQRModal(true);
      }
      if (!isNFTReceived) {
        const addressResponse = await fetch(
          `/auth/klip_result?requestId=${klipRequestId}`,
          {
            method: "GET",
          }
        );

        const addressResult = await addressResponse.json();

        if (addressResponse.ok) {
          if (popupWindow) {
            popupWindow.close();
          }

          if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
          }

          const sendNFTResponse = await fetch(
            `/escrow-api/sendNFT/${insuranceId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                card_id: cardId,
                address: addressResult.klaytn_address,
              }),
            }
          );

          if (sendNFTResponse.ok) {
            const result = await sendNFTResponse.json();
            setButtonText("NFT 보러가기");
            setKlipDeepLink(goKlip);
            setIsNFTReceived(true);
          } else {
            setIsNFTReceived(false);
          }
        }
      }
    } catch (error) {
      setIsNFTReceived(false);
    }
  };

  useEffect(() => {
    if (isNFTReceived) {
      sessionStorage.removeItem("reduxState");
      if (showQRModal) {
        setShowQRModal(false);
      }
      setShowModal(true);
    }
  }, [isNFTReceived]);

  return (
    <>
      <section className={styles.FinishWrap}>
        <div className={styles.FinishIcon}>
          {hasDeparted ? (
            <div className={styles.imgWrapper}>
              <img
                src="/images/check_has.png"
                alt="check_has"
                className={styles.imgFull}
              />
            </div>
          ) : (
            <FinishSVG />
          )}
        </div>
        <div>
          <h3 className={styles.h3_Finish}>"보험가입이 완료 되었습니다."</h3>
          <p className={styles.p_Finish}>
            가입 확인서는 이메일로 발송되었습니다.
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <div
            className={styles.buttonWrapper}
            style={{
              backgroundImage: `url('/images/${
                hasDeparted ? "departedBtn" : "Btn"
              }.png')`,
            }}
          >
            <button
              className={styles.button}
              disabled={isLoading}
              onClick={isLoading ? null : handleNFTReceive}
            >
              <div className={styles.buttonContent}>
                {!isLoading && (
                  <img
                    src={hasDeparted ? DpReceiveBtn : FinishCheck}
                    alt="Check"
                    className={styles.checkImage}
                  />
                )}
                <span className={styles.buttonText}>{buttonText}</span>
                {isLoading && (
                  <div className={styles.loading}>
                    <span className={styles.dotAnimation}>.</span>
                    <span className={styles.dotAnimation}>.</span>
                    <span className={styles.dotAnimation}>.</span>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
        {showWalletButton && <WalletButton insuranceId={insuranceId} />}
        <p
          className={
            hasDeparted ? styles.FinishTextDeparted : styles.FinishText
          }
        >
          잠시만 기다려주세요 <br /> NFT발급에 5초~ 1분까지 걸릴 수 있어요!
        </p>
      </section>
      <div>
        <p
          className={
            hasDeparted ? styles.bottomTextDeparted : styles.bottomText
          }
          onClick={openModal}
        >
          기다리는 동안 알아보기
        </p>
      </div>
      {showModal && (
        <ModalFinish
          isOpen={showModal}
          onClose={closeModal}
          isNFTReceived={isNFTReceived}
          goKlip={openKlipModal}
        />
      )}
      {showQRModal && (
        <QRCodeModal
          onClose={() => setShowQRModal(false)}
          qrCodeData={klipDeepLink}
        />
      )}
    </>
  );
}

export default Finish;
